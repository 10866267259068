import React from 'react';

import Meta from '../components/meta.js';

const OurTechnologyContinuousLearning = () => (
  <section className="__continuous-learning-wrap">
    <Meta
      title="Voltaware Continuous Learning"
      description="Voltaware AI platform helps utilities integrate new energy services."
      url="https://www.voltaware.com/our-technology/continuous-learning"
    />
    <section className="__continuous-learning-header">
      <h1>Continuous learning</h1>
    </section>
    <section className="__continuous-learning-section" id="training-our-algorithm">
      <h2>Training our algorithm</h2>
      <figure className="training-our-algorithm-desktop"><img src="/img/technology/training-our-algorithm-desktop.png" alt="Training Our Algorithm"/></figure>
      <figure className="training-our-algorithm-mobile"><img src="/img/technology/training-our-algorithm-mobile.png" alt="Training Our Algorithm"/></figure>
      <p>Voltaware trained its algorithm by installing a sensor on the wire directly connecting the appliance to the electrical socket, enabling us to collect a clean fingerprint of the particular appliance.</p>
      <p>This was then fed into the machine-learning algorithm, along with data collected from the electricity mains of each respective test home (from Voltaware Sensors/<br className="__br" />Smart Meters), allowing our data science team to train our algorithms so that they can now disaggregate the load of each appliance from the composite load of the entire house (Smart Meter/Mains Sensor data).</p>
    </section>
    <section className="__continuous-learning-section" id="providing-best-results">
      <h2>Providing you with the best results</h2>
      <p>The Voltaware data science team is regularly testing and refining our machine learning models by adding training data from more test homes, covering new geographies and appliances. Voltaware collects constant feedback on the quality and accuracy of our results from thousands of users who use the Voltaware app to further improve our results and customer satisfaction levels.</p>
      <p>To this date, Voltaware can recognise more than 15 electrical appliances, and this number is constantly growing as we gather more data and expand our reach to more countries.</p>
      <figure className="providing-best-results-desktop"><img src="/img/technology/providing-best-results-desktop.png" alt="Providing Best Results"/></figure>
      <figure className="providing-best-results-mobile"><img src="/img/technology/providing-best-results-mobile.png" alt="Providing Best Results"/></figure>
    </section>
    <section className="__continuous-learning-section" id="machine-learning-feedback">
      <h2>Machine learning feedback</h2>
      <section className="__article-wrap">
        <figure><img src="/img/technology/machine-learning-feedback.png" alt="Machine Learning Feedback" /></figure>
        <article>
          <p>Your feedback on Voltaware’s AI algorithm is paramount to us, which is why we have developed the Machine Learning feedback feature.It allows you to report any inaccuracies from your appliance breakdown.</p>
          <p>For instance, if one of your appliances has been incorrectly detected or has not been detected at all, you can directly report it in our mobile app below your daily disaggregation. By doing so, our Data Science team will adjust its AI algorithm and deliver even better results.</p>
        </article>
      </section>
    </section>
  </section>
);

export default OurTechnologyContinuousLearning;
