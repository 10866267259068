import React, { Component } from 'react';
import * as JsSearch from 'js-search';

import {faqs} from '../data/faqs';

// Components
import Meta from '../components/meta.js';
import Category from '../components/category.js';
import Faq from './faq.js';
import { Link, withRouter } from 'react-router-dom';

const SECTIONS = {
  MAIN: 'MAIN',
  SUPPORT_MATERIALS: 'SUPPORT_MATERIALS',
  SUBCATEGORY: 'SUBCATEGORY',
  SEARCH_RESULT: 'SEARCH_RESULT',
}

class Support extends Component {
  constructor(props) {
    super(props);

    this.questions = faqs.reduce((questions, faq) => {
      const items = faq.items.map(item => ({...item, subcategory: faq.subcategory}));
      return [...questions, ...items]
    },[]);

    this.searchEngine = new JsSearch.Search('question');
    this.searchEngine.addIndex('question');
    this.searchEngine.addIndex(['answer']);
    this.searchEngine.addDocuments([...this.questions]);

    this.state = {
      activeSection: SECTIONS.MAIN,
      selectedSubcategory: null,
      searchInput: '',
      searchedTerm: '',
      foundFaqs: [],
      manual: [
        {
          title: 'Installation Video (EN)',
          url: 'https://www.youtube.com/watch?v=Qn6cg_H1ayA',
          type: 'video'
        },
        {
          title: 'Installation Video (FR)',
          url: 'https://www.youtube.com/watch?v=_QrsHYSA0rE',
          type: 'video'
        },
        {
          title: 'App Demo',
          url: 'https://vimeo.com/310759173',
          type: 'video'
        },
        {
          title: 'Activating the Sensor',
          url: '/img/docs/faq/Activating_Voltaware_Sensor.pdf',
          type: 'document'
        },
        {
          title: 'Installation Single-Phase',
          url: '/img/docs/faq/Installation_Single_Phase_Energy_Monitor.pdf',
          type: 'document'
        },
        {
          title: 'Installation Three Phase',
          url: '/img/docs/faq/Installation_Three_Phase.pdf',
          type: 'document'
        },
        {
          title: 'Technical Specification',
          url: '/img/docs/faq/Tech_Spec.pdf',
          type: 'document'
        },
      ],
    };

    this.closeManuals.bind(this);
    this.toggleManualItem.bind(this);
  }

  componentWillReceiveProps(nextProps){
    this.checkURLs(nextProps);
  }

  componentDidUpdate(prevProps, prevState){
    const prevActiveSection = prevState.activeSection;
    const {activeSection} = this.state;

    if (activeSection !== prevActiveSection)
      setTimeout(() => document.querySelector("html").scrollTop = 0, 200);
  }

  toggleManualItem(itemIndex) {
    const manual = this.state.manual;
    manual[itemIndex].active = !manual[itemIndex].active;
    this.setState({
      manual
    });
  }

  //TODO remove?
  closeManuals() {
    const allOff = () => {
      return this.state.manual.map(m => {
        if (m.items) {
          m.active = false;
        }
        return m;
      });
    };

    this.setState({
      manual: allOff()
    });
  }

  componentDidMount() {
    this.closeManuals();
    this.checkURLs();
  }

  checkURLs = (props) => {
    props = props || this.props;
    const {location} = props;
    const {activeSection} = this.state;

    if (location && location.pathname === '/support' && activeSection !== SECTIONS.MAIN){
      this.goToMain();
      return;
    }

    const subsection = location && location.pathname && location.pathname.startsWith('/support/') && location.pathname.replace('/support/', '');
    if (subsection){
      switch (subsection) {
        case 'materials':
          this.goToSupportMaterials();
          break;
        case 'search':
          this.search();
          break;
        default:
          this.selectSubcategory(subsection.replace(/-/g, ' '));
      }
    }
  }

  clearSearchInput = () => {
    this.setState({
      searchInput: ''
    })
  }

  changeSearch = (e) => {
    this.setState({
      searchInput: (e.target && e.target.value) || ''
    })
  }

  goToSearch = (e) => {
    e.preventDefault();
    this.props.history.push('/support/search');
  }

  search = () => {
    const {searchInput} = this.state;

    if (searchInput && searchInput.length > 0){
      this.setState({
        foundFaqs: this.searchEngine.search(searchInput),
        searchedTerm: searchInput,
      });
      this.goToSearchResult();
    }
  }

  selectSubcategory = (subcategory) => {
    this.setState({selectedSubcategory: subcategory});
    this.goToSubcategory();
  }

  goToMain = () =>{
    this.setState({
      activeSection: SECTIONS.MAIN,
      foundFaqs: [],
    });
  }

  goToSubcategory = () =>{
    this.setState({
      activeSection: SECTIONS.SUBCATEGORY,
      foundFaqs: [],
    });
  }

  goToSearchResult = () =>{
    this.setState({
      activeSection: SECTIONS.SEARCH_RESULT,
      selectedSubcategory: null,
    });
  }

  goToSupportMaterials = () =>{
    this.setState({
      activeSection: SECTIONS.SUPPORT_MATERIALS,
      selectedSubcategory: null,
      foundFaqs: [],
    });
  }

  render() {
    const { manual, searchInput, searchedTerm, foundFaqs, selectedSubcategory, activeSection } = this.state;

    return (
      <div id="support">
        <Meta
          title="Support"
          description="Find the answers to your questions in Support."
          url="https://www.voltaware.com/support"
        />

        <section id="search" className="section container">
          <form onSubmit={this.goToSearch}>
            <div id="search-input" className={activeSection === SECTIONS.MAIN ? 'showing-main-section' : null}>
              <input value={searchInput} placeholder="Type your question" onChange={this.changeSearch} />
              {searchInput ?
                <button type="button" onClick={this.clearSearchInput} className="btn-clear-search-input">
                  <img src="/img/clear-search.svg" alt="Clear filled text" />
                </button>
              : null}
              <button type="submit" className="btn-search">
                <img src="/img/search.svg" alt="Search" />
              </button>
            </div>
          </form>
        </section>

        {activeSection === SECTIONS.MAIN ?
          <section id="main" className="section container">
            <h2>How can we help you?</h2>

            <div id="questions-main-categories">
                <Category
                  title="I Want to Know More About..."
                  iconPath="/img/more.svg"
                  subcategories={[
                    {section: 'sensor', label: 'Sensor'},
                    {section: 'voltaware-app', label: 'Voltaware App'},
                    {section: 'disaggregation', label: 'Disaggregation'},
                    {section: 'iris', label: 'IRIS'},
                  ]}
                />
                <Category
                  title="I Want to resolve an Issue About..."
                  iconPath="/img/question-mark.svg"
                  subcategories={[
                    {section: 'installing-the-sensor', label: 'Installing the Sensor'},
                    {section: 'using-the-mobile-app', label: 'Using the Mobile App'},
                    {section: 'my-disaggregation', label: 'My Disaggregation'},
                  ]}
                />
            </div>
            <Link className="btn btn-materials" to='/support/materials'>
              Support Materials
            </Link>
          </section>
        :null}

        {activeSection === SECTIONS.SUBCATEGORY || activeSection === SECTIONS.SEARCH_RESULT ?
          <section id="questions-subcategory" className="section container">
            <Faq
              selectedSubcategory={selectedSubcategory}
              foundFaqs={foundFaqs}
              searchedTerm={searchedTerm}
            />
            <Link className="btn btn-back" to='/support'>
              Back
            </Link>
          </section>
        : null}

        {activeSection === SECTIONS.SUPPORT_MATERIALS ?
          <section id="videos-manuals" className="section container">
            <h2>Support Materials</h2>
            <div className="support-items">
              <Category
                  title="Videos"
                  iconPath="/img/movie.svg"
                  subcategories={manual.filter(item => item.type === 'video').map(item => ({
                    link: item.url, label: item.title,
                  }))}
              />
              <Category
                  title="Manuals & Guides"
                  iconPath="/img/document.svg"
                  subcategories={manual.filter(item => item.type === 'document').map(item => ({
                    link: item.url, label: item.title,
                  }))}
              />
            </div>
            <Link className="btn btn-back" to='/support'>
              Back
            </Link>
          </section>
        :null}
      </div>
    );
  }
}

export default withRouter(Support);
