import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import positions from '../../src/data/open-positions';

const Team = () => {
  const [activeWorkItemIndex, setActiveWorkItemIndex] = useState(0);
  const chevronWidth = 40;

  const range = (start, stop, step = 1) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  return (
    <section className="__team-wrap">
      <section className="__team-header">
        <h1>MAKE YOUR MOVE</h1>
        <p>Towards a greener future</p>
      </section>
      <section className="__team-section" id="driving-change">
        <h2>How our teams are driving change</h2>
        <p>
          Voltaware brings together expertise from around the world to develop
          technology that advance the energy transition and democratise energy
          literacy. Our teams are at the forefront of innovation to build
          products that support this transformation.
        </p>
      </section>
      <section className="__team-section" id="work-us">
        <h2>Work with us</h2>
        <section id="work-us-carousel">
          <ItemsCarousel
            requestToChangeActive={setActiveWorkItemIndex}
            activeItemIndex={activeWorkItemIndex}
            numberOfCards={3}
            gutter={5}
            leftChevron={<img src="/img/team/previous.png" alt="" />}
            rightChevron={<img src="/img/team/next.png" alt="" />}
            outsideChevron
            chevronWidth={chevronWidth}
          >
            {range(1, 5).map((n) => (
              <figure key={n}>
                <img
                  className="__work-with-us-carousel-img"
                  src={`/img/team/work/work-00${n}.jpg`}
                  alt="Work with us!"
                />
              </figure>
            ))}
          </ItemsCarousel>
        </section>
      </section>
      <section className="__team-section" id="technologies-you-love">
        <h2>Work with the technologies that you love</h2>
        <section className="__team-technologies-you-love-wrap">
          <figure>
            <img
              src="/img/team/technologies-you-love.png"
              alt="Technologies you love"
            />
          </figure>
          <ul>
            <li>
              <h3>Artificial intelligence</h3>
              <p>
                AI is at the heart of Voltaware’s technology, we use it to drive
                cutting-edge energy insights and generate intelligence.
              </p>
            </li>
            <li>
              <h3>Machine learning</h3>
              <p>
                Our data science team uses the latest machine learning tools to
                build the powerful algorithms which make our products unique.
              </p>
            </li>
            <li>
              <h3>Microservices</h3>
              <p>
                Voltaware works with a modern, flexible architecture with
                comprehensive cloud computing.
              </p>
            </li>
            <li>
              <h3>DevOps</h3>
              <p>
                Voltaware has built automated tests and environment provisioning
                to continuously deliver features.
              </p>
            </li>
            <li>
              <h3>Big Data</h3>
              <p>
                Voltaware uses NoSQl databases and data grids containing large
                volumes of data for ingestion and storage.
              </p>
            </li>
            <li>
              <h3>Agile development</h3>
              <p>
                Voltaware’s international teams use lean techniques and the most
                advanced collaborative softwares to drive projects forward with
                agility and speed.
              </p>
            </li>
          </ul>
        </section>
        <section className="__team-section" id="why-voltaware">
          <h2>Why Voltaware?</h2>
          <section className="__team-why-voltaware-row">
            <section className="__team-why-voltaware-col">
              <figure>
                <img src="/img/team/001-group.png" alt="People-focused" />
              </figure>
              <article className="__team-why-voltaware-article">
                <h3>People-focused</h3>
                <p>
                  Your contribution to Voltaware will be recognised as you grow
                  within the company.
                </p>
              </article>
            </section>
            <section className="__team-why-voltaware-col">
              <figure>
                <img src="/img/team/002-united.png" alt="Teamwork" />
              </figure>
              <article className="__team-why-voltaware-article">
                <h3>Teamwork</h3>
                <p>
                  Voltaware teams work closely together and provide constant
                  support and mentoring.
                </p>
              </article>
            </section>
          </section>
          <section className="__team-why-voltaware-row">
            <section className="__team-why-voltaware-col">
              <figure>
                <img src="/img/team/003-book.png" alt="Learning" />
              </figure>
              <article className="__team-why-voltaware-article">
                <h3>Learning</h3>
                <p>
                  We encourage all our team members to deepen their knowledge
                  and learn new skills.
                </p>
              </article>
            </section>
            <section className="__team-why-voltaware-col">
              <figure>
                <img src="/img/team/004-leadership.png" alt="Ownership" />
              </figure>
              <article className="__team-why-voltaware-article">
                <h3>Ownership</h3>
                <p>
                  We give each team member the opportunity to lead on multiple
                  projects and products.
                </p>
              </article>
            </section>
          </section>
          <section className="__team-why-voltaware-row">
            <section className="__team-why-voltaware-col">
              <figure>
                <img src="/img/team/005-no-racism.png" alt="Diversity" />
              </figure>
              <article className="__team-why-voltaware-article">
                <h3>Diversity</h3>
                <p>
                  Voltaware brings together professionals from all around the
                  world at different stages of their career - diversity of
                  thought is our strength.
                </p>
              </article>
            </section>
            <section className="__team-why-voltaware-col">
              <figure>
                <img src="/img/team/006-idea.png" alt="Innovation-driven" />
              </figure>
              <article className="__team-why-voltaware-article">
                <h3>Innovation-driven</h3>
                <p>
                  Voltaware is always pushing at the boundaries of technology to
                  optimise our products and services.
                </p>
              </article>
            </section>
          </section>
          <section className="__team-section" id="open-positions">
            <h2>Open positions</h2>
            <p>
              If you did not find your ideal job in our current open positions,
              please send your CV to{' '}
              <a
                href="mailto:elena@voltaware.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                elena@voltaware.com
              </a>{' '}
              and we will keep you up to date.
            </p>
            <ul>
              {positions.map((position) => (
                <li key={position.link}>
                  <section className="__team-open-positions-description">
                    <h3 className="dark-grey">{position.role}</h3>
                    <p>{position.location}</p>
                  </section>
                  <a
                    href={position.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply now
                  </a>
                </li>
              ))}
            </ul>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Team;
