import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Meta from '../components/meta.js';

const Utilities = () => (
  <section className="__our-technologies-wrap">
    <Meta
      title="Our Technology: Real-Time Energy Monitoring With AI"
      description="Voltaware automatically detects all the appliances in your home to break down your electricity bill."
      url="https://www.voltaware.com/our-technology"
    />
    <section className="__our-technologies-header">
      <h1>Electricity data intelligence</h1>
    </section>
    <section className="__our-technologies-section" id="electrical-fingerprint">
      <h3 className="dark-grey">Every appliance has its own electrical fingerprint. <br className="__br" />We see them every time they are used. To provide you new actionable insights everyday.</h3>
      <section className="__electrical-fingerprint-article-wrap">
        <figure><img src="/img/technology/electrical-fingerprint.png" alt="Electrical Fingerprint"/></figure>
        <article>
          <p>The appliance fingerprint detection (disaggregation) algorithm is at the heart of Voltaware technology, unlocking new opportunities such as helping you save energy, reduce bills, improve your security and comfort, help reduce carbon footprint and more.</p>
          <p>Voltaware works by analysing electricity data either from its patented sensor or smart meter data. The Voltaware algorithm then automatically breaks down individual appliances from the total load, enabling a wide range of home insights.</p>
          <p>Voltaware can instantly detect appliance fingerprints without requiring any calibration from you as a user. The Voltaware data science team is continuously improving and adding new machine learning models to provide you with a seamless experience.</p>
          <div>
            <Link
              to="/our-technology/voltaware-sensors"
              title="Voltaware electricity sensor"
              className="btn-outlined"
            >
              What is the Voltaware Sensor?
            </Link>
          </div>
        </article>
      </section>
    </section>
    <section className="__our-technologies-section" id="core-of-voltaware">
      <h2>AI is at the core of Voltaware</h2>
      <p>Each appliance leaves its own fingerprint on the electrical wire every time it’s switched on. Over the past 5 years, the Voltaware data science team has built a library of fingerprints for each appliance type that is commonly found in the homes of our users.</p>
      <p>This has enabled us to create an algorithm which is able to disaggregate the bill down to the appliance level, unlocking a combination of energy (p2p, energy reports, tips & recommendations, real-time monitoring, etc…) and home insights (safety alerts, appliance monitoring, elderly monitoring, etc…).</p>
      <figure className="appliance-fingerprints-analysis-desktop"><img src="/img/technology/appliance-fingerprints-analysis-desktop.png" alt="AI is at the core of Voltaware" /></figure>
      <figure className="appliance-fingerprints-analysis-mobile"><img src="/img/technology/appliance-fingerprints-analysis-mobile.png" alt="AI is at the core of Voltaware" /></figure>
      <div>
        <HashLink
          smooth
          to="/support/disaggregation#how-many-appliances-can-be-disaggregated"
          title="Appliances that can be recognised"
          scroll={el => {
            setTimeout(() => {
              el.scrollIntoView(true);
              window.scrollBy(0, -100);
            }, 1)
          }}
          className="btn-outlined"
        >
          What Appliances <br className="__br" />Can Be Recognised?
        </HashLink>
      </div>
    </section>
    <section className="__our-technologies-section" id="automatic-detection">
      <h2>Automatic detection</h2>
      <section className="__article-wrap">
        <figure><img src="/img/technology/cellphone_detection_donut.png" alt="Voltaware app in cellphone" /></figure>
        <article>
          <p>Voltaware differentiates between the consumption of the main appliances at home without requiring any user input or training period - thanks to its unique appliance signature libraries.</p>
          <p>The results are instant as our algorithm breaks down appliance fingerprints based on the Voltaware appliance library. Voltaware is unique because it automatically disaggregates consumption without needing specific in-house training - greatly enhancing the customer experience.</p>
          <p>Voltaware users can help improve their experience by:</p>
          <ul>
            <li>Providing some general information about appliances they use and their profiles;</li>
            <li>Giving us feedback about the accuracy of our results from time to time.</li>
          </ul>
          <p>This allows our algorithm to become more precise and help detect a wider range of appliances at your home with greater accuracy.</p>
        </article>
      </section>
    </section>
  </section>
);

export default Utilities;
