import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import * as serviceWorker from './serviceWorker';

// Routes
import Routes from './routes.js';

// Components
import Footer from './components/footer.js';
import Header from './components/header.js';
import ScrollToTop from './components/scroll-to-top.js';

import './styles/main.sass';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <ToastProvider>
      <Router>
        <ScrollToTop />
        <Header />
        {Routes}
        <Footer />
      </Router>
    </ToastProvider>,
    rootElement
  );
} else {
  render(
    <ToastProvider>
      <Router>
        <ScrollToTop />
        <Header />
        {Routes}
        <Footer />
      </Router>
    </ToastProvider>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
