import React from 'react';
import { Link } from 'react-router-dom';


const Category = (props) => {
    const {subcategories, title, iconPath} = props;

    return (
        <div className="category">
            <div className="title-with-icon">
                <img alt="Title Icon" src={iconPath} />
                <h3>{title}</h3>
            </div>

            {subcategories && subcategories.length ?
                <div className="subcategories">
                <ul>
                    {subcategories.map((subcategory, i) => (
                        subcategory.section ?
                            <li key={i}><Link to={`/support/${subcategory.section}`}>{subcategory.label}</Link></li>:
                            <li key={i}>
                                <a
                                    href={subcategory.link}
                                    title={subcategory.label}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                    {subcategory.label}
                                </a>
                            </li>
                    ))}
                </ul>
                </div>
            :null}
        </div>
    );
}

export default Category;
