import React from 'react';

// Components
import Meta from '../components/meta.js';

class ConsumerStandardTerms extends React.Component {
  render() {
    return (
      <section id="consume-standard-terms">
        <Meta
          title="Terms and Conditions"
          description="Review our Terms and Conditions"
          url="https://www.voltaware.com/terms-and-conditions"
        />

        <section className="section container">
          <div className="col">
            <h4 className="title no-border-bottom">
              Terms and conditions of your residential customer service agreement for Voltaware services
            </h4>
          </div>
          <div className="col">
            <h5 className="title">IMPORTANT: PLEASE NOTE THE SPECIFIC INSTALLATION REQUIREMENTS IN SECTION B</h5>
          </div>
          <div className="col">
            <p>These terms and conditions set out the agreement between (1)
            you; references to <span className="bold">“you”</span> and <span className="bold">“your”</span> are to persons who purchases the Voltaware
            services and/or the person who uses the Voltaware services);
            (2) Voltaware Services Limited, trading as ‘Voltaware’. References to <span className="bold">“we”</span>,
            <span className="bold">“us”</span> or <span className="bold">“our”</span> are
            references to Voltaware Services Limited or other members of its group. We are a limited company registered
            in England and Wales (company number 9844696), and our registered address is Heston Cross House, 230 High
            Street, Swanage, Dorset, United Kingdom BH19 2PQ and our address for correspondence is: 1st Floor, 282 Earls
            Court Road, London, SW5 9AS, UK. Our VAT number is GB251512047.</p>

            <p>Your use of the Voltaware <span className="bold">services</span> is at all times subject to these terms and
            conditions as set out below (or referred to below in links), any additional terms set out in your customer
            contract, any service charge receipt and all applicable laws. When referring to
            <span className="bold">“agreement”</span> we mean all of these things. The <span className="bold">agreement</span> is legally binding, which means that we are making
            promises to each other about the <span className="bold">services</span> we provide and how you are permitted to use those <span className="bold">services</span>.
            By using the Voltaware <span className="bold">services</span> you agree to these terms and conditions. If you do not
            agree to these terms and conditions, you must stop using the Voltaware <span className="bold">services</span> immediately. </p>

            <p>Please read through these terms and conditions carefully, as they apply to all residential
            customers who take services from us.</p>

            <p>The words highlighted in <span className="bold">bold</span> throughout these terms and conditions have special meanings which
            can be found in the Glossary at the end of these terms and conditions. Also, where we say "include" or
            "including" in these terms and conditions and then give examples, it does not mean that these are the only
            examples of what we are referring to.</p>
          </div>
          <div className="col">
            <h5 className="title">A. Voltaware services</h5>
          </div>
          <div className="col">
            <p>1. The services we will
            provide you are “Disaggregating your electricity bill by individual appliances in your home, tracking your energy use in real-time and developing reports on the Voltaware
            mobile app”. These are referred to in this document as the <span className="bold">“services”</span>.
            </p>

            <p>2. To
            use the <span className="bold">services</span>, you must register for a user account and provide certain information about yourself as
            prompted by the applicable registration form.  The information required is the property type, size, the
            number of people living, the number of bedrooms, if you generate power and a list of appliances.</p>

            <p>3. Access to our <span className="bold">services</span> is provided via
            the Voltaware application (this is referred to in this document as the <span className="bold">“application”</span> and more
            particularly described in the Glossary below).  Such access is generally permitted on a subscription
            basis. If you are being provided with the service on a trial basis, we will withdraw access to
            the <span className="bold">services</span> if you do not pay your subscription at the end of the trial.</p>

            <p>4. The system requirements for receiving
              our <span className="bold">services</span> on your devices are:  </p>

            <div className="one-level-margin">
              <p>a. Android phones require the
              Android 4.4 version or later; and </p>
              <p>b. Apple phones require the iOS 10
              version or later.</p>
            </div>

            <p>Please note that although we support tablets, the <span className="bold">application</span> is not optimised for them. </p>

            <p>5. We aim to provide a
            continuous, high-quality service using reasonable care and skill. However, we may modify or
            temporarily suspend the <span className="bold">services</span>, or part of them, to the extent necessary for us to carry out
            maintenance, technical repair, enhancement or emergency work. We will try to provide any maintenance
            services that we believe are necessary for us to provide the <span className="bold">services</span> to you. We will try to
            minimise the impact of this on you and we will restore the <span className="bold">services</span> to you as soon as we can. Where
            possible, we will notify you if this occurs between the hours of 9 am and 23 pm and is due to last
            for more than 1 hour.</p>

            <p>6. We
            may from time to time develop patches, bug fixes, updates, upgrades and other modifications to improve
            the performance of the software which supports the <span className="bold">application</span> and related <span className="bold">services</span>. These may be
            automatically installed without providing any additional notice to you or receiving any additional
            consents from you. You hereby consent to such automatic updates. If you do not want to receive such
            updates, your only remedy is to stop using the <span className="bold">equipment</span> and uninstalling the <span className="bold">application</span>.</p>

            <p>7. We will always aim to provide you with continued access to <span className="bold">services</span> but we will
            not be liable for interruptions or other problems with the <span className="bold">services</span> which
            are <span className="bold">matters beyond our reasonable control</span>, which include without limit the matters
            listed in Section H (Matters beyond our reasonable control). There may be other reasons too.
            In these cases, we do not accept responsibility for not providing you with the <span className="bold">service</span>.</p>

            <p>8. You agree that you
            will tell us about any fault in the <span className="bold">services</span> and <span className="bold">equipment</span> by contacting our customer services team,
            who will aim to respond as promptly as possible. Please contact us on <a href="https://voltaware.com/support"
                className="simple-link">https://voltaware.com/support</a>.
            In many circumstances it may be possible for us to correct a fault remotely.</p>

            <p>9. Due to the nature of
            the internet, we cannot guarantee specific levels of performance for the <span className="bold">services</span>. Although we
            aim to offer you the best service possible, we make no promise that our
            online services will meet your requirements and we cannot guarantee that the service will be fault
            free.</p>

            <p>10. We do not guarantee or promise any
            specific level of energy savings or other monetary benefit from the use of the <span className="bold">equipment</span>,
            the <span className="bold">services</span> or any feature thereof. Actual energy savings and monetary benefits vary with
            factors beyond our control or knowledge.
            From time to time, we may use the services to provide you with information that is unique
            to you and your energy usage and suggest an opportunity to save money on energy bills if you adopt
            suggestions or features of the <span className="bold">equipment</span> or the <span className="bold">services</span>.
            You acknowledge that these recommendations are
            not a guarantee of actual savings, and you agree not to seek monetary or other remedies from us if your
            savings differ.</p>

            <p>11. You confirm that you
            are the owner of, and that you have obtained all necessary consents to use, the email name, mailbox
            name or any other name selected by you in connection with the <span className="bold">services</span> and to have
            the <span className="bold">equipment</span> installed in your <span className="bold">home</span>. You represent and warrant that: </p>

            <div className="one-level-margin">
              <p>a. all required registration
              information you submit to us is truthful and accurate;  </p>

              <p>b. you will maintain the accuracy
              of such information; and  </p>

              <p>c. your use of the <span className="bold">services</span> does
              not violate any UK or other applicable law or regulation.</p>
            </div>

            <p>You are entirely responsible for maintaining the confidentiality of your account login
            information and for all activities that occur under your account.</p>

            <p>12. Please see <span className="bold">our acceptable use policy</span> on <a href="https://voltaware.com/acceptable-use" className="simple-link">https://voltaware.com/acceptable-use</a> for more detail on using the <span className="bold">services</span>.
            </p>

            <p>13. The <span className="bold">services</span> are for private use by
            you and members of your household. They must not be used for any activities not reasonably expected of
            someone using the <span className="bold">services</span> for domestic purposes.</p>
          </div>
          <div className="col">
            <h5 className="title">B. Ordering and installation of equipment </h5>
          </div>
          <div className="col">
            <p>1. If
            you are in the UK, we will dispatch your <span className="bold">equipment</span> to you within 48 hours of you successfully placing
            your order via a courier service. We will activate the <span className="bold">services</span> in accordance with the procedure
            identified in paragraph B.14 below.</p>

            <p>2. We
            are not responsible for delays in delivering you
            the <span className="bold">equipment which are beyond our reasonable control</span> (these are listed
            in Section H (Matters beyond our reasonable
            control)).</p>

            <p>3. We
            reserve the right, in our sole discretion, to refuse or cancel any order and limit order quantity. We
            may also require additional qualifying information prior to accepting or processing any order. We
            reserve the right to refuse or cancel your order if we suspect you are purchasing products for
            resale.</p>

            <p>4. All purchases of physical items from us are made pursuant to a shipment
            contract. Provided you have paid for the <span className="bold">equipment</span> in advance of delivery, title to the <span className="bold">equipment</span> pass
            to you upon our delivery to the freight carrier. We and/or the freight carrier will be responsible for
            any <span className="bold">equipment</span> loss or damage that occurs when the <span className="bold">equipment</span> is in transit to you. If you have not paid
            for your <span className="bold">equipment</span> (for example, if we are providing it to you on a trial basis), it belongs to us until
            you have paid us and you should keep it safe so that we are able to take it back without other people
            claiming to have rights in it (for example, you cannot sell it to anyone or offer it to someone as
            security for your debt). If you do not wish to take out a subscription to the services once your trial
            period has expired, you have to return our <span className="bold">equipment</span> following the instructions provided with
            your <span className="bold">equipment</span> or pay for it.</p>

            <p>5. We
            (or members of our group) will in any event retain ownership in all patent rights, mask works,
            copyrights, trademarks, service marks, related goodwill, and confidential and proprietary information)
            in the <span className="bold">equipment</span>; and all modifications to, and derivative works based upon, the <span className="bold">equipment</span>.</p>

            <p>6. We
            do not have to send you the <span className="bold">equipment</span> or to provide you the <span className="bold">services</span>, or otherwise keep to
            this agreement to the extent the following affects our ability to do so, if:
            </p>

            <div className="one-level-margin">
              <p>a. your <span className="bold">home</span> is in a country where
              we cannot provide the <span className="bold">services</span> or we are unable to activate the <span className="bold">services</span> at your <span className="bold">home</span> for any
              reason; </p>

              <p>b. you have previously misused
              our <span className="bold">services</span>; or </p>

              <p>c. your computer or device (or its
              operating software) does not work correctly or as reasonably expected for internet access.</p>
            </div>

            <p>7. If
            we do not send you the <span className="bold">equipment</span> that you ordered for any reason, we shall not be under any liability
            whatsoever to you for any failure to provide you with the <span className="bold">service</span> provided by that <span className="bold">equipment</span> but we will
            refund you any payments you have already made for the <span className="bold">equipment</span> and the provision of the
            related <span className="bold">service</span>  and this <span className="bold">agreement</span> will come to an end in relation to your order from us.
            </p>

            <p>8. Once the <span className="bold">equipment</span> is
            received, although installation of the sensor may seem straightforward (see <a href="https://voltaware.com/support#videos-manuals" className="simple-link">https://voltaware.com/support#videos-manuals</a> ), please ensure that it is installed by a <span className="bold underlined">qualified electrician</span>. Once installed, please do
            not move any <span className="bold">equipment</span> yourself.  Should you later wish to alter the routing or location of any
            existing cables or wall sockets or move the <span className="bold">equipment</span> to another place, you should contact a
            qualified electrician.</p>

            <p>9. You are solely
            responsible for installing the <span className="bold">equipment</span>. The <span className="bold">equipment</span> is usually supplied with an instruction
            manual which you should make available to your electrician. We can recommend a network of installers
            that are familiar with the <span className="bold">equipment</span>. You should contact voltaware@support.com to obtain this
            information. If you have received the <span className="bold">equipment</span> without a printed manual, please follow this link
            for an electronic version: <a href="https://voltaware.com/img/docs/faq/Installation_Single_Phase_Energy_Monitor.pdf"className="simple-link">Installation Single Phase Energy Monitor FAQ</a>.</p>

            <p>10. There may be laws in the country where
            you install the <span className="bold">equipment</span> applicable to where and how to install the <span className="bold">equipment</span>. You should check that
            you are in compliance with all relevant laws and codes in your jurisdiction. We are not responsible for
            any injury or damage caused by installation.</p>

            <p>11. The <span className="bold">equipment</span> is connected to
            dangerous voltages. <span className="bold">IMPROPER USE OR INSTALLATION CAN BE DANGEROUS OR EVEN FATAL</span>. It may also impair the
            performance of the <span className="bold">equipment</span>, lead to damage or destruction of property and may invalidate the Equipment
            Warranty referred to in paragraph D.4
            (Warranty/looking after your equipment) below. Please that you follow these guidelines for installation
            and use:  </p>

            <div className="one-level-margin">
              <p>a. Have the installation done by a
              qualified electrician, according to local electrical codes; </p>

              <p>b. Do not try to open the monitor
              of the <span className="bold">equipment</span>, touch any internal parts, or try to repair it without first contacting
              Voltaware support on support@voltaware.com.</p>

              <p>c. If you believe the monitor, the
              current transformers (CTs), or cables may have been damaged, do not try to use them but please
              contact Voltaware support on support@voltaware.com.</p>

              <p>d. Install the <span className="bold">equipment</span> where it
              will not be exposed to direct sunlight or extremely low or high temperatures.
              </p>
            </div>

            <p>12. We will not be
            liable to you for any loss or damage caused by your installation of the <span className="bold">equipment</span>, unless this loss or
            damage is caused by our fault.</p>

            <p>13. For receiving the <span className="bold">services</span> you need to
            make sure that your equipment, such as your computer or device (tablet or mobile phone) will connect to
            the equipment by a wi-fi connection. You are responsible for making sure your <span className="bold">equipment</span> works properly,
            your wif-fi does not have firewall blocking, you have a wi-fi connection and your home wiring and
            electrical system is in good order and are compatible with the <span className="bold">equipment</span> and the <span className="bold">services</span>. You
            acknowledge that you are responsible for all charges from providers of such facilitating services and
            for complying with their terms of use.</p>

            <p>14. To activate the <span className="bold">equipment</span>, you will need to download the Voltaware <span className="bold">application</span> from The App
            Store or The Google play store. You need to sign up and “provision” the sensor forming part of the <span className="bold">equipment</span> using the product
            key on your sensor. The sensor will try to connect to your wi-fi network. After successfully connecting
            the sensor to the wif-fi network, the sensor light will be green.</p>

            <p>15. We may monitor any content or material
            transmitted over the system where required by law or an enforcement authority, or as part of any actual
            or potential criminal or suspected fraudulent activity.  To make sure you are always getting the best
            possible customer service, we may monitor and record phone conversations you have with our teams. Any
            data we collect will be in accordance with <span className="bold">our privacy policy:</span> <a href="https://voltaware.com/privacy-policy"
                className="simple-link">https://voltaware.com/privacy-policy</a>. Our privacy policy forms part of
            your agreement with us.</p>
          </div>
          <div className="col">
            <h5 className="title">C. Cooling off rights</h5>
          </div>
          <div className="col">
            <p>1. If
            you have ordered any of the <span className="bold">services</span> online, by telephone or from an agent attending at your <span className="bold">home</span> and
            have entered into a new contract with us you have a statutory right to cancel an order for
            those <span className="bold">services</span> within the <span className="bold">“cooling off period”</span>, which is 14 days from the latest of: </p>

            <div className="one-level-margin">
              <p>a. the day after the delivery of
              the <span className="bold">equipment</span>;  or</p>

              <p>b. the <span className="bold">service</span> start date.</p>
            </div>

            <p>2. You can cancel your order by making any
            clear statement to us regarding that cancellation by: </p>

            <div className="one-level-margin">
              <p>a. dialling 020 7460 1521 from any phone.
              Call costs may vary; or </p>

              <p>b. writing
              to <a className='simple-link' href="mailto:support@voltaware.com">support@voltaware.com</a>; or</p>

              <p>b. using the model cancellation form found <a className='simple-link' href="/order-cancellation">here</a>.</p>
            </div>

            <p>3. If
            you cancel the <span className="bold">services</span> during the <span className="bold">cooling off period</span>, we will refund to you all payments received from
            you, including the costs of any delivery for any <span className="bold">equipment</span> you ordered. If you cancel an order
            for <span className="bold">equipment</span>, we will automatically cancel your order for the <span className="bold">services</span> unless you inform us
            otherwise.</p>

            <p>4. If
            you requested a <span className="bold">service</span> to begin during the <span className="bold">cooling off period</span> we may require you to pay us an amount
            proportionate to any <span className="bold">services</span> which we have provided you up until the cancellation date.</p>

            <p>5. If
            you cancel during the <span className="bold">cooling off period</span> you must return promptly any <span className="bold">equipment</span> which you have been
            provided, using the returns method provided with the <span className="bold">equipment</span> or any other method we advise. You will be responsible for 
            the postage costs in returning the <span className="bold">equipment</span>, unless the <span className="bold">equipment</span> is faulty, in which case we will pay the costs of return postage.</p>

            <p>6. We
            will make any refunds due to you for exercising your rights to cancel during the <span className="bold">cooling off
            period</span> (subject to any deductions due to us) within 14 days, starting the day after we are informed that
            you wish to cancel.  We will make any refund using the same method of payment you used to pay us.</p>
          </div>
          <div className="col">
            <h5 className="title">D. Warranty/looking after your equipment </h5>
          </div>
          <div className="col">
            <p>1. We
            may upgrade and update the <span className="bold">equipment</span> from time to time. We will give you notice beforehand where we
            reasonably can if we believe such an update may materially affect your service.</p>

            <p>2. You are responsible for making sure
            that our <span className="bold">equipment</span> is safe and used properly at all times.  To do this, you agree to do the
            following: </p>

            <div className="one-level-margin">
              <p>a. follow the manufacturer's
              instructions and any other reasonable instructions we have given you;
              </p>

              <p>b. keep the <span className="bold">equipment</span> in
              your <span className="bold">home</span> and under your control; </p>

              <p>c. not tamper with disassemble,
              misuse, neglect or damage the <span className="bold">equipment</span>; and </p>

              <p>d. not remove, tamper with or
              cross out any words or labels on the <span className="bold">equipment</span>.</p>
            </div>

            <p>3. As regards any <span className="bold">equipment</span> provided to you on a trial basis, prior to you
            purchasing such <span className="bold">equipment</span>, it is provided to you with no warranty whatsoever, express, implied or
            statutory, including, but not limited to warranties of title, non-infringement, merchantability or
            fitness for a particular purpose prior to you purchasing such <span className="bold">equipment</span>. Once the <span className="bold">equipment</span> has been
            purchased, the warranty referred to in paragraph D.4
            below shall apply. In any event, you acknowledge that any <span className="bold">equipment</span> provided
            on a trial basis may not work properly or in accordance with its documentation. You may have
            other statutory rights. However, to the full extent permitted by law, the duration and scope of
            statutorily required warranties, if any, shall be limited to the maximum extent permitted.</p>

            <p>
              4. <span className="bold">Equipment Warranty:</span> We promise you that for one (1) year from 
              the date when you receive the <span className="bold">equipment</span> from us it will be (i) as 
              described, (ii) fit for purpose, (iii) of satisfactory quality and (iv) free from defects in 
              the materials used to make it or defects in how it is put together (its workmanship). This warranty 
              applies if the used <span className="bold">equipment</span> has been paid for. Otherwise, please see 
              paragraph D.3 above.
            </p>

            <p>5. If
            you discover a defect in the materials used to make the <span className="bold">equipment</span> or how it is put together (its
            workmanship) within one (1) year from the date when you receive the <span className="bold">equipment</span> from us, we will
            either: </p>

            <div className="one-level-margin">
              <p>a. repair or replace any defective <span className="bold">equipment</span> or its component with new or refurbished
              components of the same or (if the original <span className="bold">equipment</span> or components are not available) greater functionality; or
              </p>

              <p>b. accept the return of the <span className="bold">equipment</span> and refund the money that the person who bought
              the <span className="bold">equipment</span> paid for the <span className="bold">equipment</span> by its original purchaser.</p>
            </div>

            <p>We will chose which of option (a) or option (b) above will be provided to you and make sure that
            you get either replacement <span className="bold">equipment</span> or a refund within 45 days of us receiving the <span className="bold">equipment</span> that you think
            is
            defective back. This is your only remedy for breach of the <span className="bold">Equipment Warranty</span> set out in paragraph D.4 above and
            it is subject to paragraph
            D.7 below. </p>
            <p>Any <span className="bold">equipment</span> that has either been repaired or replaced under the <span className="bold">Equipment Warranty</span> will be
            covered by the terms of this paragraph for the longer of (a) ninety (90) days from the date of delivery of
            the
            repaired <span className="bold">equipment</span> or replacement <span className="bold">equipment</span>, or (b) the remaining period of the <span className="bold">Equipment Warranty</span>. This
            means
            that, for example, if we delivered the <span className="bold">equipment</span> to you on 1 January and we replaced it in June, the last
            day
            you can make a claim is 31 December in the year that you bought it.  If, however, we replaced the <span className="bold">equipment</span>
            on 1
            December in the year that you bought it, the replacement <span className="bold">equipment</span> will be guaranteed until 1st March the
            following year.</p>
            <p>The <span className="bold">Equipment Warranty</span> is transferable from the original purchaser to subsequent owners, but the
            period for which it extends will not be extended in duration or expanded in coverage for any such transfer.
            </p>

            <p>6. In
            order to make use of the <span className="bold">Equipment Warranty</span> you must: </p>

            <div className="one-level-margin">
              <p>a. notify us of
              the intention to claim by visiting support@voltaware.com during the period while the <span className="bold">Equipment Warranty</span> is still valid
              and provide a description of the failure; and </p>

              <p>b. comply with our return shipping
              instructions.</p>
            </div>

            <p>7. We will have no warranty obligations with respect to returned <span className="bold">equipment</span> if we
            determine, acting reasonably after examination of the returned <span className="bold">equipment</span>, that the <span className="bold">equipment</span> you return
            has any of the following features: </p>

            <div className="one-level-margin">
              <p>a. it is <span className="bold">equipment</span> marked as “Not
              for Sale” </p>

              <p>b. it has been subject to:  </p>
            </div>

            <div className="two-level-margin">
                <p>i. modifications,
                alterations, tampering, or improper maintenance or repairs;
                </p>
                <p>ii. handling, storage,
                installation, testing, or use not in accordance with our instructions;
                </p>
                <p>iii. abuse or misuse; </p>
                <p>iv. breakdowns,
                fluctuations, or interruptions in electric power; or  </p>
                <p>v. acts
                of God, including, but not limited to, lightning, flood, tornado, earthquake, or
                hurricane.</p>
            </div>

            <p>8. We
            will bear all costs of return shipping to the owner and will reimburse any shipping costs incurred by
            the owner, except with respect to the <span className="bold">equipment</span> which has any of the features which are referred to in
            paragraph D.7 above, for which owner will bear
            all shipping costs.</p>

            <p>9. We
            are not responsible for use of the <span className="bold">equipment</span> outside of the United Kingdom. You are responsible for
            complying with all applicable laws and regulations of the country for which the <span className="bold">equipment</span> is destined.
            We are not liable or responsible if you break any such law.</p>

            <p>10. Except as specifically referred to in
            this section the <span className="bold">equipment</span>, software, service and site operated by us, are provided “as is” and “as
            available” and we and our licensors and suppliers expressly disclaim any warranties and conditions of
            any kind, whether express or implied, including the warranties or conditions of merchantability, fitness
            for a particular purpose, accuracy, and non-infringement. We and our licensors and suppliers make no
            warranty that defects will be corrected or that we: (i) will meet your requirements; (ii) will be
            compatible with your electrical system, home network, computer or mobile device; (iii) will be available
            on an uninterrupted, timely, secure, or error-free basis; or (iv) will be accurate or reliable or
            produce specific results. No advice or information, whether oral or written, obtained by you from us or
            though us shall create any warranty. When you install, setup or use the <span className="bold">equipment</span> you may be given the
            opportunity to alter defaults or choose particular settings. The choices you make can cause damage or
            lead to non-recommended operation of your connected <span className="bold">equipment</span> or systems. You assume all liability for
            such damage when you choose particular settings or set or adjust defaults.</p>

            <p>11. As part of the <span className="bold">services</span> we provide you
            information regarding the products in your <span className="bold">home</span>. All information regarding the products in your home is
            provided “as is” and “as available”. We cannot guarantee that it is correct or up to date. In cases
            where it is critical, accessing such information through the Voltaware <span className="bold">application</span> is not a substitute
            for direct access of the information in the home.</p>
          </div>
          <div className="col">
            <h5 className="title">E. Using the services</h5>
          </div>
          <div className="col">
            <p> 1.You are responsible for the way the <span className="bold">equipment</span> and <span className="bold">services</span> are
            used. You must not do any of the acts or allow anyone else to do the acts in relation to
            the <span className="bold">equipment</span> and/or services which are contrary to our <span className="bold">acceptable use
            policy:</span> <a href="https://voltaware.com/acceptable-use"
                className="simple-link">https://voltaware.com/acceptable-use</a>.</p>

            <p>2. If we believe that
            you are using the <span className="bold">services</span> contrary to the <span className="bold">acceptable use policy</span> (<a href="https://voltaware.com/acceptable-use"
                className="simple-link">https://voltaware.com/acceptable-use</a>) referred to in
            paragraph E.1 and
            consider it to be a serious issue, we are entitled to reduce, suspend and/or terminate any or all of
            the <span className="bold">services</span> without giving you notice.  Where we do reduce or suspend the affected <span className="bold">service</span> we will
            reinstate the <span className="bold">service</span> if we have been able to resolve the issues with you within a reasonable time
            but we will need your reasonable co-operation to do so.  We will terminate
            the <span className="bold">services</span> and <span className="bold">agreement</span> with you where this is not possible or if we consider, acting reasonably,
            that your breach is sufficiently serious and requires us to terminate the <span className="bold">agreement</span>. We may also
            immediately terminate the <span className="bold">services</span> we provide you and this <span className="bold">agreement</span> where we reasonably consider
            your misuse to be very serious or harmful to others.</p>

            <p>3. We
            reserve the right to remove by immediate notice material placed on our servers by you or other users
            which we, in our reasonable opinion, believe breaks this <span className="bold">agreement</span>.</p>

            <p>4. You agree to take responsibility for
            all liabilities, claims and losses which are in any way connected with you misusing the <span className="bold">services</span>, or
            with getting the <span className="bold">services</span> our permission, and to repay fully any costs or losses of this kind
            which we may suffer.  This also applies if you do not meet your responsibilities under this Section E.</p>
          </div>
          <div className="col">
            <h5 className="title">F. Paying for your services </h5>
          </div>
          <div className="col">
            <p>1. If we have offered you a free trial, if
            you do not pay for your <span className="bold">equipment</span> and enter into a paid subscription for the <span className="bold">services</span> before the end of
            your trial period, your <span className="bold">services</span> will be disconnected at the end of the trial period and you will have
            to return or pay separately for the <span className="bold">equipment</span>. You can read more about the return of the <span className="bold">equipment</span> which
            we have provided on a trial basis in paragraph B. 4 (Ordering and installation of equipment) </p>

            <p>2. Unless we have reached an agreement
            with someone else (such as your employer) that they will pay for the <span className="bold">services</span> provided to you, you are
            responsible for and must pay the charges for the <span className="bold">services</span> as set out in our <span className="bold">price guide</span> in paragraph F.9.
            below (the <span className="bold">price guide</span>) or as otherwise
            notified to you (for example as set out in your order or change of service summary or your bill),
            together with any applicable value added tax or other applicable taxes.</p>

            <p>3. We will provide you with all necessary
            payment details of the E-billing service that we deploy from time to time.  We will bill you
            electronically via E-billing in advance and you must provide us with a valid and current e-mail account
            to which we will send your bills. We agree to collect and process all payments received by it from the
            E-billing service deployed promptly and ensure that they are applied in settlement of the charges to
            which they relate.</p>
            
            <p>For E-billing <span className="bold">Octopus customers</span> we are currently using the link on the following 
              website: <a href="https://octopus.voltaware.com/purchase/" className="simple-link">https://octopus.voltaware.com/purchase/</a> to 
              arrange payment where the following process will apply:
            </p>
            
            <div className="two-level-margin">
              <p>i. <span className="bold">Octopus customers</span> will click on Order Now button on the VWO website.</p>
              <p>ii. <span className="bold">Octopus customers</span>  will provide their Octopus ID and go through the process of email verification.</p> 
              <p>iii. <span className="bold">Octopus customers</span> will be asked to fill out their shipping details: full name(mandatory); 
                  Delivery address (this has to be in the UK), mobile number(optional); 
                  Octopus ID (mandatory) and email address (mandatory).
              </p>
              <p>iv. <span className="bold">Octopus customers</span>  will  be informed of the order details and payment amount and will be required to fill in 
              their payment information and credit card details.</p>
              <p>v. <span className="bold">Octopus customers</span> will then be informed by us that their order is completed and that the  <span className="bold">equipment</span> is on its way.</p>
            </div>

            <p>4. We can change the charges set out in
            the <span className="bold">price guide</span>, but if we do so, this may entitle you to end this <span className="bold">agreement</span> once
            the <span className="bold">minimum period</span> has come to an end.
            You can read more about this in paragraphs F.9 and L (Changing this agreement).
            Any changes will not affect any order that you have already placed.</p>

            <p>5. The following provisions apply
            regarding billing and payment: </p>

            <div className="one-level-margin">
              <p>a. Under this <span className="bold">agreement</span>, if you
              ask for any changes to the <span className="bold">services</span> provided by us, these changes will be reflected by adding or
              removing proportionate amounts to your first bill after the change and to your payments every
              month after that.</p>

              <p>b. By providing a credit card or
              other payment method accepted by us, you represent and warrant that you are authorised to use
              the designated payment method and that you authorise us (or our third-party payment processor)
              to charge you for the total amount of your order (including any applicable taxes and other
              charges).</p>

              <p>c. You must
              resolve any problem we encounter in order to proceed with your order. You must ensure that your
              payments are received by us by the due date for payment shown on your bill.  If you do not pay
              your bills on time, if any payment is cancelled or is not cleared or if the payment method you
              provide cannot be verified, is invalid or is otherwise not acceptable, you may need to pay
              interest or other charges as set out in our price guide. We may also suspend or cancel
              the <span className="bold">services</span>. Reminders will normally be sent for late bill payments.
              </p>
            </div>

            <p>6. The accuracy of the e-mail address you
            provide to us for E-billing purposes is entirely your responsibility. You shall remain fully responsible
            and liable to pay any bills of which notification has been sent to the e-mail address you have specified
            whether or not: </p>

            <div className="one-level-margin">
              <p>a. you access that e-mail account
              and read the relevant e-mail; </p>

              <p>b. you are disconnected from your
              e-mail account (for any reason); or </p>

              <p>c. for any other reason (other
              than our negligence) you fail to read the relevant notification.</p>
            </div>

            <p>7. Due to the nature of the internet and
            the need by our E-billing provider from time to time to update, upgrade and maintain the
            E-billing service, we cannot guarantee uninterrupted access to the E-billing service.</p>

            <p>8. You must provide promptly and
            accurately all the information which may be needed so that we can perform our respective obligations
            under this <span className="bold">agreement</span>. You must also tell us immediately if any of your details change.</p>

            <p className="underlined bold">9. Price guide </p>

            <p>Below is an explanation of the different charges you may incur.</p>

            <p className="underlined bold">Equipment</p>

            <p>When we provide you with <span className="bold">equipment</span> that enables receipt of your service, either for a new 
            service or as a replacement for an existing service, you will normally be required to pay. The standard 
            price is 
            set out in the standard pricing schedule available on request. The cost of the <span className="bold">equipment</span> does not include 
            installation. Please see Section B (Ordering and installation of <span className="bold">equipment</span>) as 
            regards the installation of the <span className="bold">equipment</span> in general. 
            For any <span className="bold">Octopus customers</span> who subscribe to the services by 1 April 2023 (the <span className="bold">"pilot term”</span>), 
            we will charge £149 and their access to the <span className="bold">Voltaware App</span> will be unlimited and guaranteed for 24 months. 
            Access will continue for 24 months irrespective of whether you continue to qualify as an <span className="bold">Octopus customer</span> or not. 
            After 24 months the access to the services will be subject to a subscription fee. 
            If we request that you pay a subscription price after 24 months and you fail to pay the subscription amount requested, 
            you will not be entitled to the reimbursement of any amounts that you paid for the <span className="bold">equipment</span> nor any of the costs that you may have incurred, 
            e.g. the cost of installation and the provisions of paragraph F..c. above will apply. For any <span className="bold">Octopus customers</span> who subscribe to the service after the <span className="bold">pilot term</span>, 
            the subscription amount will be £24 per year.</p>

            <p>Shipping costs to places other than the UK may be charged in addition to the standard price and 
            will vary depending on where you require us to ship the <span className="bold">equipment</span>.</p>

            <p className="underlined bold">Standard subscription to your services</p>

            <p>Our standard subscription charges are set out in the standard pricing schedule available on
            request. These are updated annually and once your annual subscription has expired you will be charged the
            new
            subscription price. </p>

            <p className="underlined bold">Early Disconnection</p>

            <p>As part of a fixed term contract with us, you agree that you’ll continue with your chosen <span className="bold">services</span> for
            a minimum period of 12 months (the “minimum period”). If you decide to end part or all of your <span className="bold">services</span> during
            the minimum period then you will not be entitled to a reimbursement of the fee paid. The
            minimum period will restart every time you renew your subscription.</p>

            <p className="underlined bold">Failure to return equipment provided on a trial basis</p>

            <p>If you have decided not to subscribe to the <span className="bold">services</span> at the end of any trial period and you fail
            to return or make available for removal any item of the <span className="bold">equipment</span> which we have provided to you on a trial
            basis, you may have to pay extra charges for such <span className="bold">equipment</span>, including the replacement cost or reasonable
            recovery costs. In addition to our other rights, we reserve the right to bring legal proceedings against you
            for
            the return of the <span className="bold">equipment</span>.</p>

            <h5 className="title">G. Liability to you </h5>

            <p>1. We do not restrict or exclude liability to you for:
            </p>
            <div className="one-level-margin">
              <p>a. death or personal injury
              resulting from our own (or our agents' or contractors') negligent act or failure to act; or </p>

              <p>b. fraud; or </p>

              <p>c. any legal rights and remedies
              available to you as a consumer in relation to the <span className="bold">services</span>.</p>
            </div>

            <p>None of these rights and remedies are affected by this <span className="bold">agreement</span>. For information on these
            rights and remedies please visit the Citizens Advice website www.citizensadvice.org.uk or call 0808 223
            1133.
            </p>

            <p>2. Except as set out in paragraph G.1 we will not be
            liable to you for: </p>

            <div className="one-level-margin">
              <p>a. any loss or damage which is not
              a reasonably foreseeable consequence of our negligence or breach of this <span className="bold">agreement</span>. Loss or
              damage is reasonably foreseeable if either it is obvious that it will happen or if, at the time
              the agreement was made, both we and you knew it might happen (for example, if you and we
              discussed it); or </p>

              <p>b. any business loss (including
              loss of profits, business, revenue, contracts or anticipated savings, wasted expenses or any
              other purely financial losses) even if such loss was reasonably foreseeable.
              </p>
            </div>

            <p>3. Except as set out in paragraph G.1 our liability to
            you is limited to the
            listed price of our <span className="bold">equipment</span> which you purchased from us.</p>

            <p>4. When we carry out any obligation under
            this <span className="bold">agreement</span>, our duty is to exercise the reasonable care and skill of a competent service provider
            only.</p>

            <p>5. We
            will not be liable to you for the accuracy, fitness for purpose, completeness or legality of any
            information accessed, received or transmitted using the <span className="bold">equipment</span> or <span className="bold">services</span> unless we are the author
            or creator of this information or material, or for transmitting or receiving, or failure to transmit or
            receive, any material through the <span className="bold">equipment</span> or <span className="bold">services</span>.</p>

            <p>6. We
            will not be liable for any failure of safety, security or other alarm systems or monitors due to: </p>
            <div className="one-level-margin">
              <p>a. incompatibility with our
              network; or </p>

              <p>b. power or network outages that
              are not our fault; or </p>

              <p>c. any other <span className="bold">matters beyond our reasonable control</span> or not due to our fault.</p>
            </div>

            <p>7. We
            will not be liable to you for the contents of any material from other individuals or organisations which
            may be accessed through the <span className="bold">equipment</span> or <span className="bold">services</span>.  We also reserve the right to block access to any
            such material.</p>

            <p>8. As
            a consumer you also have other legal rights and remedies that apply in addition to any provided to you
            under this <span className="bold">agreement</span> or common law. Some of the key rights you have as a consumer are contained in the
            Consumer Rights Act 2015, which provides legal remedies to you where we have, for example, not exercised
            reasonable care and skill in providing the <span className="bold">services</span> to you, or where goods or digital content we provide
            to you are faulty or not as described.  These remedies may include the right to ask us to fix the
            problem or to a price reduction. Consumer law also gives you rights if we provide you with misleading
            information that leads you to enter into an agreement with us.  For information on your legal rights and
            remedies available to you as a consumer, please visit the Citizens Advice website at <a
                href="http://www.citizensadvice.org.uk/" className="simple-link">www.citizensadvice.org.uk</a>.</p>
          </div>
          <div className="col">
            <h5 className="title">H. Matters beyond reasonable control </h5>
          </div>
          <div className="col">
            <p>1. Neither you nor us shall be liable to the other for
            failing to do what is
            promised under this <span className="bold">agreement</span> if it is prevented from doing so by matters beyond our reasonable control,
            including without limitation fire, lightning, flood, severe weather, explosion, epidemic, riot, civil
            commotion, any strike, lockout or other industrial action, act of God, war, warlike hostilities or
            threat of war, terrorist activities, accidental or malicious damage, industrial disputes, any
            prohibition or restriction by any government or other legal authority or failure of public or private
            telecommunications networks.</p>

            <p>2. If
            you or us claim to be unable to perform our respective obligations under this <span className="bold">agreement</span> (either on time
            or at all) in any of the circumstances set out in the paragraph H.1 above, that
            person shall notify the other of the nature and extent of the
            circumstances in question as soon as practicable.</p>

            <p>3. This section H shall cease to apply when such
            circumstances have ceased to have effect
            on the performance of this <span className="bold">agreement</span> and the person affected shall give notice to the other person that
            the circumstances have ceased. If any circumstance relied on by a person for the purposes of this
            section H continues for more than three months, the other person shall be
            entitled to terminate this <span className="bold">agreement</span>.</p>
          </div>
          <div className="col">
            <h5 className="title">I. Suspending the services</h5>
          </div>
          <div className="col">
            <p>1. We may suspend or reduce any of
            the <span className="bold">services</span>, immediately without notice for as long as the suspension event persists if: </p>

            <div className="one-level-margin">
              <p>a. you have broken
              this <span className="bold">agreement</span> in a serious and non-minor way (and in such an instance we may also reduce the
              level of <span className="bold">services</span> affected); </p>

              <p>b. you cancel any direct debit
              that we have agreed to set up and we haven't agreed an alternative means of payment;
              </p>

              <p>c. we have reason to believe that
              you have provided us with false, inaccurate or misleading information either for the purpose of
              obtaining the <span className="bold">services</span> and/or the <span className="bold">equipment</span> or at any time during the provision of
              the <span className="bold">services</span>; </p>

              <p>d. we believe that you or another
              person at your home have committed, or may be committing, any fraud against us, and/or any other
              person or organisation by using the <span className="bold">services</span> or <span className="bold">equipment</span> (or both);
              </p>

              <p>e. you or anyone you authorise to
              deal with us on your behalf acts in a way towards our staff or agents which we reasonably
              consider to be inappropriate; or </p>

              <p>f. in our reasonable opinion it is
              necessary to do so, for example when dealing with threats to the security or integrity of the
              network or the <span className="bold">services</span> we provide.</p>
            </div>

            <p>In these cases, we will normally contact you and try to resolve the issue where reasonably
            possible. However, where applicable we may make a charge to reflect our costs in connection with suspending
            and
            starting supply of the <span className="bold">services</span> again. You must pay this charge before you can use the <span className="bold">services</span> again.  You
            may
            also be liable for all charges for <span className="bold">services</span> during this period of suspension.</p>

            <p>2. We may also suspend any of
            the <span className="bold">services</span> for as long as the suspension event persists, immediately without notice if: </p>
            <div className="one-level-margin">
              <p>a. maintenance, repairs or
              improvements to any part of the <span className="bold">equipment</span>, the <span className="bold">services</span>, the network or the <span className="bold">system</span> need to be
              carried out, including for example, when dealing with threats to the security or integrity of
              the network or the <span className="bold">services</span> we provide; or </p>

              <p>b. we have to do so by law. </p>
            </div>

            <p>3. For your and our protection we can also
            suspend the <span className="bold">services</span> if your usage has increased to such an extent that it appears, in our reasonable
            opinion, that the <span className="bold">services</span> are not being used in a manner consistent with reasonable residential
            domestic use. We will make reasonable efforts to contact you before suspending the <span className="bold">services</span> but we are
            not liable for any loss you may suffer through this suspension.  We will not provide the <span className="bold">services</span> again
            until we are satisfied that you are using the <span className="bold">services</span> consistently with reasonable domestic use.</p>
          </div>
          <div className="col">
            <h5 className="title">J. Other ways to end this agreement</h5>
          </div>
          <div className="col">
            <p>1. We may end this <span className="bold">agreement</span> at any time
            (including during or at the end of any minimum period) by giving you 30 days’ notice and refunding you a
            proportionate amount of any charges paid for <span className="bold">services</span> for the period when you are unable to use
            them.</p>

            <p>2. If we: </p>
            <div className="one-level-margin">
              <p>a. increase our monthly charges
              under this <span className="bold">agreement</span>; </p>

              <p>b. increase any of our other
              charges that are you are required to pay under this <span className="bold">agreement</span> in a way which is likely to
              materially disadvantage you; or </p>

              <p>c. make any changes to the terms
              and conditions of this <span className="bold">agreement</span> which are likely to materially disadvantage you; or
              </p>

              <p>d. make any changes to
              the <span className="bold">services</span> which are likely to materially disadvantage you; or </p>

              <p>e. if there is a permanent loss of
              the <span className="bold">services</span>, </p>
            </div>

            <p>we will notify you of this and you may cancel this <span className="bold">agreement</span> by giving us at least 30 days'
            notice. You need to give us that cancellation notice within 30 days of us notifying you. Any increased
            charges
            will not apply during your 30-day cancellation notice period.</p>

            <p>3. We may end this <span className="bold">agreement</span> immediately
            by giving you notice if in our reasonable opinion it is necessary to do so for security, technical or
            operational reasons.</p>

            <p>4. When this <span className="bold">agreement</span> ends or you cancel
            a service, permanently block access to your account on the <span className="bold">application</span>. You will no longer be able to
            use the <span className="bold">equipment</span>.</p>

            <p>5. If this <span className="bold">agreement</span> is ended for any
            reason, or if any of the <span className="bold">services</span> are cancelled, we will be entitled to keep any money held (including
            any advance payments) and to use that money to pay any obligation or debt you may owe under
            this <span className="bold">agreement</span>.</p>
          </div>
          <div className="col">
            <h5 className="title">K. If you break this agreement </h5>
          </div>
          <div className="col">
            <p>1. We may end this <span className="bold">agreement</span> immediately
            if: </p>

            <div className="one-level-margin">
              <p>a. you become insolvent or
              bankrupt, you enter into any arrangement with your creditors, or if any legal action is taken or
              threatened against your property; </p>

              <p>b. we believe that you or another
              person at your home have committed, or may be committing, any fraud against us, or any other
              person or organisation whether or not by using the <span className="bold">services</span> or <span className="bold">equipment</span> (or both);
              </p>

              <p>c. you break any of
              this <span className="bold">agreement</span> in a serious and non-minor way and, if you are able to put things right, you have
              not done so within 7 days (or such other longer period as we specify) of us asking you to do
              so; </p>

              <p>d. we have reason to believe that
              you have provided us with false, inaccurate or misleading information either for the purpose of
              obtaining the <span className="bold">services</span> and/or the <span className="bold">equipment</span> from us, or at any time during the provision of
              the <span className="bold">services</span>; </p>

              <p>e. any permission under which we
              are entitled to connect, maintain, modify or replace the <span className="bold">equipment</span> is ended for any reason; </p>

              <p>f. we are required to comply with
              applicable law or regulation, or comply with an order, instruction or request of Government, an
              emergency services organisation or other competent administration, legal or regulatory
              authority; </p>

              <p>g. you or anyone you authorise to
              deal with us on your behalf acts in a way towards our staff or agents which we reasonably
              consider to be inappropriate; </p>

              <p>h. we are specifically entitled to
              do so under any other section of this <span className="bold">agreement</span>; or </p>

              <p>i. we validly suspend or reduce
              any of the <span className="bold">services</span> in accordance with Section I (Suspending the
              services) for a period of 30 days or more.</p>
            </div>

            <p>2. If you break this <span className="bold">agreement</span> and we
            choose to overlook it, we can still end the <span className="bold">agreement</span> with you if you break it again.</p>

            <p>3. If we end this <span className="bold">agreement</span> because you
            have not paid the charges which you are liable to pay under this <span className="bold">agreement</span>), we are entitled to: </p>

            <div className="one-level-margin">
              <p>a. charge you for all charges up
              to the date the <span className="bold">agreement</span> ends; and/or </p>

              <p>b. prevent and/or disrupt access
              to the <span className="bold">services</span>.</p>
            </div>

            <p>4. If you break this <span className="bold">agreement</span> by
            committing fraud or any other criminal activity, we will report you to the police, who may take legal
            action.</p>
          </div>
          <div className="col">
            <h5 className="title">L. Changing this agreement </h5>
          </div>
          <div className="col">
            <p>1. Subject to your rights set out further
            in this section and paragraph J.2 above, we may
            at any time modify, amend or alter the terms of this <span className="bold">agreement</span> and/or the <span className="bold">services</span> if: </p>
            <div className="one-level-margin">
              <p>a. there is any change or
              amendment to any law, code of practice, guidance or regulation which applies to us or the <span className="bold">services</span> provided to you; </p>

              <p>b. we decide that the <span className="bold">services</span> should
              be altered for reasons of quality of service or otherwise for the benefit of customers
              or, in our reasonable opinion, it is necessary to do so in order to continue to provide the
              <span className="bold">services</span>; </p>

              <p>c. for security, technical or
              operational reasons; </p>

              <p>d. we introduce new services, new
              service features, service improvements or equipment; </p>

              <p>e. the changes or additions are
              minor and do not affect materially disadvantage you; or </p>

              <p>f. in all other events, where we
              reasonably determine that any modification to a relevant system, our network or a change in
              trading, operating or business practices or policies is necessary to maintain or improve
              the <span className="bold">services</span> provided to you.</p>
            </div>

            <p>2. We may withdraw your chosen <span className="bold">services</span> for
            commercial or operational reasons or if we are unable to continue providing it
            for <span className="bold">matters beyond our reasonable control</span>. If we decide to do this, we will move you on to our nearest equivalent or
            better current services, unless you ask us to move to other more expensive services. If the services we
            have decided to remove causes the price you pay to increase we will also give you notice of the
            change(s) at least 30 days before the changes take effect and you may cancel the services affected in
            accordance with paragraph J.2 above </p>

            <p>3. We may change our charges at any time.
            Any changes to our annual charges will be published by us on our website and we will also give you
            notice of the change(s) at least 30 days before the changes take effect by email and you may cancel the
            services affected in accordance with paragraph J.2
            above.</p>
          </div>
          <div className="col">
            <h5 className="title">M. Notices </h5>
          </div>
          <div className="col">
            <p>Any notices we give to you must be in writing and be delivered by hand, ordinary post to you at
            your home or sent electronically.  Any notices you give to us should be given in line with the directions
            set
            out on [web link?] or in your welcome pack.  Any notice period will start from the day on which the notice
            is
            delivered if it is delivered by hand, 2 working days (i.e. excluding Saturdays, Sundays and public holidays)
            after the date it was posted if sent by ordinary post, or from the date of successful transmission if it is
            sent
            electronically.</p>
          </div>
          <div className="col">
            <h5 className="title">N. Transfer of agreements </h5>
          </div>
          <div className="col">
            <p>This <span className="bold">agreement</span> is personal to you and you may not transfer your account or any of your rights
            and responsibilities under this <span className="bold">agreement</span> without our written agreement.  For business reasons we may
            transfer
            any of our rights and responsibilities under this <span className="bold">agreement</span> without your permission as long as we ensure
            that
            the transfer will not affect your rights under this <span className="bold">agreement</span> in a negative way.</p>
          </div>
          <div className="col">
            <h5 className="title">O. The law and how your complaints are resolved </h5>
          </div>
          <div className="col">
            <p>1. This <span className="bold">agreement</span> will be governed by the
            law of England and Wales, unless you live in Scotland or Northern Ireland, in which case it will be
            governed by Scots or Northern Irish law respectively.</p>

            <p>If we have a dispute about this <span className="bold">agreement</span> and you want to take court proceedings, you must do so
            in the courts of one of the 4 parts of the United Kingdom (England, Wales, Scotland or Northern Ireland) of
            which its courts will have exclusive jurisdiction.</p>

            <p>2. If any part of the <span className="bold">agreement</span> cannot be
            enforced, the rest of it will still apply.</p>

            <p>3. We are fully committed to addressing
            all complaints, fully and fairly, and within a reasonable time frame. 
            If you have a compliant then please contact our Customer Support Team 
            at support@voltaware.com or call us on 020 7460 152.</p>

            <p>4. If we have not reached an agreed
            settlement within eight weeks of receiving your complaint, or we agree in writing before the eight weeks
            is up that the dispute should be settled by independent adjudication, you can refer your complaint for
            independent consideration through Alternative Dispute Resolution by the Communications and Internet
            Services Adjudication Scheme (CISAS) at www.cisas.org.uk.  This service is free of charge. 
            Please note that you are not required to settle the dispute using arbitration and you have the 
            choice to bring an action in the courts if you prefer (see section O.1 above).</p>

            <p>5. You may also be able to refer a dispute
            to the European Online Dispute Resolution (ODR) platform at http://ec.europa.eu/odr.  The ODR platform
            is a web-based platform which is designed to help consumers who have bought goods or services online.
            It provides access to independent alternative dispute resolution services which are usually free for
            you to use.</p>
          </div>
          <div className="col">
            <h5 className="title">Glossary</h5>
          </div>
          <div className="col">
            <p><span className="bold">agreement</span>: this is defined in the second paragraph of this document.</p>
            <p><span className="bold">application</span>: mobile or desktop application
            powered by or linked to the Voltaware cloud and carrying the Voltaware name.</p>
            <p><span className="bold">equipment</span>: the <span className="bold">equipment</span> is the Voltaware sensor and other equipment which we supply to you as
            an essential part of providing the <span className="bold">services</span> (including upgrades and replacements). This will include the
            sensor, cables and installation manual and package. </p>
            <p><span className="bold">home</span>: the residential property the <span className="bold">equipment</span> is
            installed, and to which we agree to supply the <span className="bold">services</span>.</p>
            <p><span className="bold">Equipment Warranty</span>: the warranty set out in paragraph D.4
            (Warranty/looking after your equipment).</p>
            <p><span className="bold">insights</span>: the information and data relating to the energy usage in your home that we create using our AI powered technology 
            and supplied to you via our mobile <span className="bold">application</span>.</p>
            <p><span className="bold">matters beyond our reasonable control</span>: without limitation, the matters listed in Section H (Matters beyond our
            reasonable control).</p>
            <p><span className="bold">price guide</span>: the terms set out in paragraph F.9
            (Paying
            for your services)</p>
            <p><span className="bold">Services</span>: the services provided by us and described in paragraph A.1 (Voltaware services).</p>
          </div>
        </section>
      </section>
    );
  }
}

export default ConsumerStandardTerms;
