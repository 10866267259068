import React from 'react';
import PropTypes from 'prop-types';

const BigBanner = ({image: backgroundImage, mode = 'left', mobile = false, shouldHideForMobile = false, children }) => {
  const modeClass = mode === 'centred' ? 'single-centred-column' : '';
  const mobileClass = mobile === true ? 'mobile-banner' : shouldHideForMobile === true ? 'hide-in-mobile' : '';

  return (
    <section
      className={`big-banner ${mobileClass}`}
      style={{ backgroundImage: `url(/img/${backgroundImage})` }}
    >
      <div className={`big-banner-wrap ${modeClass}`}>{children}</div>
    </section>
  );
};

BigBanner.propTypes = {
  image: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['left', 'centred']),
  mobile: PropTypes.bool,
}

export default BigBanner;
