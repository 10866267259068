import React from 'react';

class BlogRelatedPosts extends React.Component {
  render() {
    return (
      <div id="related-wrapper">
        <div className="related-title">
          <h3>Related posts</h3>
        </div>

        <div id="blog-related-posts" className="section container">
          {this.props.posts.map((post, i) => {
            let alt = 'voltaware blog ' + post.title;

            return (
              <div className="blog-post" key={i}>
                {post.link ? (
                  <a href={post.link}>
                    <img src={post.img} alt={alt} className="img-responsive" />
                  </a>
                ) : (
                  <img src={post.img} alt={alt} className="img-responsive" />
                )}
                <div className="post-title">
                  <span>Posted by Voltaware</span>

                  {post.link ? (
                    <a href={post.link}>{post.title}</a>
                  ) : (
                    post.title
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default BlogRelatedPosts;
