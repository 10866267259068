import React from 'react';
import { Facebook, Twitter, Mail, Whatsapp } from 'react-social-sharing';

const SocialSharing = () => (
  <div className="social-sharing">
    <p>Share</p>
    <Facebook simple link={window.location.href} />
    <Twitter simple link={window.location.href} />
    <Whatsapp simple link={window.location.href} />
    <Mail simple solidcircle link={window.location.href} />
  </div>
);

export default SocialSharing;
