import React, { useState } from 'react'; 
import { useToasts } from 'react-toast-notifications';

import Meta from '../components/meta.js';

const Cancellation = () => {
  const initialFields = {
    name: '',
    email: '',
    orderedOn: '',
    receivedOn: '',
    consumer: '',
    address: '',
  };

  const { addToast } = useToasts();

  const toast = (message, appearance = 'success') => addToast(message, { appearance, autoDismiss: true, placementType: 'bottom-center' });
  
  const [loading, setLoading] = useState(false);
  const [emailFields, setEmailFields] = useState(initialFields);
  const [validationError, setValidationError] = useState(false);
  const [wrongDates, setWrongDates] = useState({ orderedOn: false, receivedOn: false });

  const resetForm = () => setEmailFields(initialFields);

  const handleSubmit = e => {
    e.preventDefault();

    sendEmail();
  };

  const handleOnChange = name => (e) => {
    e.preventDefault();

    const wrongOrderedOn = name === 'orderedOn';
    const wrongReceivedOn = name === 'receivedOn';
    
    if (wrongOrderedOn || wrongReceivedOn) {
      setWrongDates({
        ...wrongDates,
        ...(wrongOrderedOn ? { 'orderedOn': false } : {}),
        ...(wrongReceivedOn ? { 'receivedOn': false } : {})
      });
    }

    setEmailFields({ ...emailFields,  [name]: e.target.value });
  };

  const emailSuccess = () => {
    toast('Thank you.\nA member of our team will be in touch.', 'success');
  }

  const emailFailure = () => {
    toast('An error has occurred.\nPlease try again later or contact us directly at enquiries@voltaware.com', 'error');
  }

  const sendEmail = () => {
    const wrongOrderedOn = !/2[0-9]{3}-[0-9]{2}-[0-9]{2}/.test(emailFields.orderedOn);
    const wrongReceivedOn = !/2[0-9]{3}-[0-9]{2}-[0-9]{2}/.test(emailFields.receivedOn);

    setWrongDates({ 'orderedOn': wrongOrderedOn, 'receivedOn': wrongReceivedOn });

    if(emailFields.name === '' || emailFields.email === '' || emailFields.orderedOn === '' || emailFields.receivedOn === '' || emailFields.consumer === '' || emailFields.address === '' || wrongOrderedOn || wrongReceivedOn) {
      return setValidationError(true);
    }

    setLoading(true);

    const data = {
      from: `${emailFields.name} <${emailFields.email}>`,
      subject: "New order cancellation from Voltaware's website",
      text: `<p>I/We ${emailFields.name} hereby give notice that I/We ${emailFields.name} wish to cancel my/our contract of sale of the Voltaware Sensor and for the supply of the Voltaware subscription service,</p>
<p>Ordered on ${emailFields.orderedOn} /received on ${emailFields.receivedOn},</p>
<p>Name of consumer(s) ${emailFields.consumer}</p>
<p>Address of consumer(s) ${emailFields.address}</p>`
    };

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch('/.netlify/functions/send-email', config)
      .then(res => {
        setLoading(false);
        setValidationError(false);

        if (res.ok) {
          resetForm();

          return emailSuccess();
        }

        return emailFailure();
      })
      .catch(_ => {
        setLoading(false);
        setValidationError(false);

        return emailFailure();
      });
  }
  
  return (
    <section id="order-cancellation">
      <Meta
        title="Order Cancellation Form"
        description="To cancel your order, fill out this form."
        url="https://www.voltaware.com/cancellation"
      />
      <section className="section container">
        <div className="col">
            <h4 className="title no-border-bottom">Order cancellation</h4>
        </div>
        <div className="col">
            <p>Complete and return this form only if you wish to <strong>withdraw</strong> from the contract.</p>

            <form className={loading ? 'loading' : ''} onSubmit={handleSubmit}>
              <p className="justified">I/We <input type="text" name="name" id="name" value={emailFields.name} onChange={handleOnChange("name")} className={validationError && emailFields.name === "" ? "field-validation-error" : ""}/> hereby give notice that I/We <input type="text" name="name" id="name" value={emailFields.name} onChange={handleOnChange("name")} className={validationError && emailFields.name === "" ? "field-validation-error" : ""}/> wish to cancel my/our contract of sale of the Voltaware Sensor and for the supply of the Voltaware subscription service,</p>
              <p className="justified">Ordered on <input type="date" name="orderedOn" id="orderedOn" value={emailFields.orderedOn} onChange={handleOnChange("orderedOn")} className={"small "+(validationError && wrongDates.orderedOn ? "field-validation-error" : "")}/> /received on <input type="date" name="receivedOn" id="receivedOn" value={emailFields.receivedOn} onChange={handleOnChange("receivedOn")} className={"small "+(validationError && wrongDates.receivedOn ? "field-validation-error" : "")}/>,</p>
              <p className="justified">Name of consumer(s) <input type="text" name="consumer" id="consumer" value={emailFields.consumer} onChange={handleOnChange("consumer")} className={"big "+(validationError && emailFields.consumer === "" ? "field-validation-error" : "")}/></p>
              <p>Address of consumer(s) <input type="text" name="address" id="address" value={emailFields.address} onChange={handleOnChange("address")} className={"big "+(validationError && emailFields.address === "" ? "field-validation-error" : "")}/></p>
              <p>Email <input type="email" name="email" id="email" value={emailFields.email} onChange={handleOnChange("email")} className={validationError && emailFields.email === "" ? "field-validation-error" : ""}/></p>
              
              <p><input className="btn-outlined" type="submit" disabled={loading} value="Cancel" /></p>
            </form>

            <p>© Crown copyright UK 2013.</p>
        </div>
      </section>
    </section>);
}

export default Cancellation;
