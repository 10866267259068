import React from 'react';
import ReactTooltip from 'react-tooltip';
import { NavLink } from 'react-router-dom';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };
  }

  menuToggle = () => {
    this.setState({
      active: !this.state.active
    });
  };

  menuHide = () => {
    this.setState({ active: false });
  };

  handleSubmenuHide = () => {
    // hack to hide menu (which is a tooltip) on clicking
    // https://github.com/wwayne/react-tooltip/issues/449#issuecomment-456682847
    this.tooltip.tooltipRef = null;
    ReactTooltip.hide();
  };

  render() {
    let className = '';

    if (this.state.active) {
      className += ' active';
    }

    return (
      <header className={className}>
        <div className="container">
          <button onClick={this.menuToggle}>
            <span />
            <span />
            <span />
          </button>
          <NavLink to="/" onClick={this.menuHide}>
            <img
              src="/img/voltaware-energy-monitor.svg"
              alt="voltaware energy monitor"
            />
          </NavLink>

          <nav>
            <NavLink
              to="/request-a-demo"
              activeClassName="active"
              className="call-to-action"
              onClick={this.menuHide}
            >
              Request a demo
            </NavLink>
            <NavLink
              to="/amazon-shop"
              activeClassName="active"
              onClick={this.menuHide}
            >
              Shop
            </NavLink>
            <NavLink
              to="/our-technology"
              activeClassName="active"
              onClick={this.menuHide}
              data-tip
              data-for='our-technology-submenu'
            >
              Our technology
            </NavLink>
            <NavLink
              to="/our-offer-to-utilities"
              activeClassName="active"
              onClick={this.menuHide}
            >
              For utilities
            </NavLink>
            <NavLink
              to="/team/about-us"
              activeClassName="active"
              onClick={this.menuHide}
              data-tip
              data-for='team-submenu'
            >
              Team
            </NavLink>
            <NavLink
              to="/support"
              activeClassName="active"
              onClick={this.menuHide}
            >
              Support
            </NavLink>

            <NavLink
              to="/blog"
              activeClassName="active"
              onClick={this.menuHide}
              className="blog"
            >
              Blog
            </NavLink>
          </nav>
        </div>

        {/* Our technology section's submenu, implemented with ReactTooltip */}
        <ReactTooltip
          id='our-technology-submenu'
          aria-haspopup='true'
          effect="solid"
          arrowColor="transparent"
          offset={{top: 22, right: 52}}
          clickable={true}
          className="header-submenu"
          ref={el => this.tooltip = el}
          delayHide={1}
          backgroundColor='transparent'
        >
          <nav>
            <NavLink
                to="/our-technology/voltaware-sensors"
                activeClassName="active"
                onClick={this.handleSubmenuHide}
                exact
              >
                Voltaware Sensors
            </NavLink>
            <NavLink
                to="/our-technology/smart-meters-integration"
                activeClassName="active"
                onClick={this.handleSubmenuHide}
                exact
              >
                Smart Meter Integration
            </NavLink>
            <NavLink
                to="/our-technology/continuous-learning"
                activeClassName="active"
                onClick={this.handleSubmenuHide}
                exact
              >
                Continuous Learning
            </NavLink>
          </nav>
        </ReactTooltip>

        {/* Team section's submenu, implemented with ReactTooltip */}
        <ReactTooltip
          id='team-submenu'
          aria-haspopup='true'
          effect="solid"
          arrowColor="transparent"
          offset={{top: 22, right: 32}}
          clickable={true}
          className="header-submenu"
          ref={el => this.tooltip = el}
          delayHide={1}
          backgroundColor='transparent'
        >
          <nav>
            <NavLink
                to="/team/about-us"
                activeClassName="active"
                onClick={this.handleSubmenuHide}
                exact
              >
                About us
            </NavLink>
            <NavLink
                to="/team/work-with-us"
                activeClassName="active"
                onClick={this.handleSubmenuHide}
                exact
              >
                Work with us
            </NavLink>
          </nav>
        </ReactTooltip>
      </header>
    );
  }
}

export default Header;
