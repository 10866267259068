import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Pages
import Home from './pages/home.js';
import Team from './pages/team.js';
import AboutUs from './pages/team-about-us.js';
import ContactUs from './pages/contact-us.js';
import JoinUs from './pages/join-us.js';
import AcceptableUse from './pages/acceptable-use.js';
import AmazonShop from './pages/amazon-shop.js';
import OurTechnology from './pages/our-technology.js';
import OurTechnologyVoltawareSensors from './pages/our-technology-voltaware-sensors.js';
import OurTechnologySmartMeters from './pages/our-technology-smart-meters.js';
import OurTechnologyContinuousLearning from './pages/our-technology-continuous-learning.js';
import Utilities from './pages/utilities.js';
import IntegrateWithVoltaware from './pages/integrate-with-voltaware.js';
import Blog from './pages/blog.js';
import BlogPost from './pages/blog-post.js';
import { BlogPosts } from './pages/blog/blog-posts.js';
import Support from './pages/support.js';
import PrivacyPolicy from './pages/privacy-policy.js';
import RequestADemo from './pages/request-a-demo.js';
// import TermsAndConditions from './pages/terms-and-conditions.js'; - Maria asked on July, 20th, 2023 to replace this by consumer standard terms
import ProductTerms from './pages/product-terms.js';
import ConsumerStandardTerms from './pages/consumer-standard-terms.js';
import Cancellation from './pages/cancellation.js';

const RedirectedHome = () => <Home redirected={true} />;

const routes = (
  <Switch>
    <Route exact path="/" component={Home} />

    <Route exact path="/home" component={RedirectedHome} />

    <Route path="/acceptable-use" component={AcceptableUse} />
    <Route path="/consumer-standard-terms" component={ConsumerStandardTerms} />
    <Route exact path="/amazon-shop" component={AmazonShop} />
    <Route exact path="/our-technology" component={OurTechnology} />
    <Route exact path="/our-technology/voltaware-sensors" component={OurTechnologyVoltawareSensors} />
    <Route exact path="/our-technology/smart-meters-integration" component={OurTechnologySmartMeters} />
    <Route exact path="/our-technology/continuous-learning" component={OurTechnologyContinuousLearning} />
    <Route path="/our-offer-to-utilities" component={Utilities} />
    <Route path="/integrate-with-voltaware" component={IntegrateWithVoltaware} />
    <Route exact path="/blog" component={Blog} />

    {BlogPosts.map((post, index) =>
      post.link ? (
        <Route component={BlogPost} key={index} path={post.link} />
      ) : (
        ''
      )
    )}

    <Route path="/team/about-us" component={AboutUs} />
    <Route path="/team/work-with-us" component={Team} />
    <Route path="/faq" component={Support} />
    <Route path="/support" component={Support} />
    <Route path="/privacy-policy" component={PrivacyPolicy} />
    <Route path="/request-a-demo" component={RequestADemo} />
    <Route path="/terms-and-conditions" component={ConsumerStandardTerms} />
    <Route path="/product-terms" component={ProductTerms} />
    <Route path="/join-us" component={JoinUs} />
    <Route path="/contact-us" component={ContactUs} />
    <Route path="/order-cancellation" component={Cancellation} />
    
    <Redirect from="/our-technology/voltacare" to="/our-technology" />
    <Redirect from="/voltacare/terms-and-conditions" to="/our-technology" />
    <Redirect from="/team" to="/team/about-us" />
    <Redirect to="/home" />
  </Switch>
);

export default routes;
