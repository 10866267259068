import React from 'react';

// Components
import Meta from '../components/meta.js';

class AcceptableUse extends React.Component {
  render() {
    return (
      <section id="acceptable-use">
        <Meta
          title="Acceptable use"
          description="Review our Acceptable Use"
          url="https://www.voltaware.com/acceptable-use"
        />

        <section className="section container">
          <div className="col">
            <h4 className="title no-border-bottom" style={{ borderBottom: 0 }}>
              Acceptable use policy
            </h4>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Who we are and how to contact us</strong></p>
            <p>
              <a className="simple-link" href="https://www.voltaware.com">
                www.voltaware.com
              </a>{' '}
              is a site operated by Voltaware Services Ltd. (we or us). We are a
              limited company registered in England and Wales (company number
              9844696), and our registered address is Heston Cross House, 230
              High Street, Swanage, Dorset, United Kingdom BH19 2PQ and our
              address for correspondence is: 1st Floor, 282 Earls Court Road,
              London, SW5 9AS, UK. Our VAT number is GB251512047.
            </p>
            <p>
              This acceptable use policy sets out the terms between you and us
              under which you may access our website{' '}
              <a className="simple-link" href="https://www.voltaware.com">
                www.voltaware.com
              </a>{' '}
              (our site) and use our application, equipment or any of our services.
              This acceptable use policy applies to all users of, and visitors to,
              our site, users of our application, equipment and any of our services.
              Your use of our site means that you accept, and agree to abide by,
              all the policies in this acceptable use policy, which supplement our
              our standard terms and conditions use{' '}
              <a
                className="simple-link"
                href="https://www.voltaware.com/terms-and-conditions"
              >
                http://www.voltaware.com/terms-and-conditions
              </a>
              {' '}and consumer standard terms{' '}
              <a
                className="simple-link"
                href="https://www.voltaware.com/consumer-standard-terms"
              >
                https://www.voltaware.com/consumer-standard-terms
              </a>
              .
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Our site is only for users in the UK</strong></p>
            <p>Our site is directed to people residing in the United Kingdom. We do not represent that content available on or through our site is appropriate for use or available in other locations.</p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>You must keep your account details safe</strong></p>
            <p>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>
            <p>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of this acceptable use policy.</p>
            <p>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a
                className="simple-link"
                href="mailto:support@voltaware.com"
              >support@voltaware.com</a>.</p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>How you may use material on our site</strong></p>
            <p>We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it.  Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
            <p>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
            <p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
            <p>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
            <p>You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
            <p>If you print off, copy or download any part of our site in breach of this acceptable use policy, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
            <p>You may use our site, application, equipment or any of our services only for lawful purposes.</p>
            <p>You may not use our site application, equipment or any of our services:</p>
            <p className="one-level-margin">
              In any way that breaches any applicable local, national or
              international law or regulation.
            </p>
            <p className="one-level-margin">
              In any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect.
            </p>
            <p className="one-level-margin">
              For the purpose of harming or attempting to harm minors in any
              way.
            </p>
            <p className="one-level-margin">
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our content standards.
            </p>
            <p className="one-level-margin">
              To transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam).
            </p>
            <p className="one-level-margin">
              To knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware.
            </p>
            <p>You also agree:</p>
            <p className="one-level-margin">
              Not to reproduce, duplicate, copy or re-sell any part of our site,
              application equipment or any of our services in contravention of the
              provisions of our standard terms and conditions{' '}
              <a
                className="simple-link"
                href="https://www.voltaware.com/terms-and-conditions"
              >
                https://www.voltaware.com/terms-and-conditions
              </a>
              {' '}and consumer standard terms{' '}
              <a
                className="simple-link"
                href="https://www.voltaware.com/consumer-standard-terms"
              >
                https://www.voltaware.com/consumer-standard-terms
              </a>
              {' '}or any other terms which govern our relationship.
            </p>
            <p className="one-level-margin">
              Not to break, or circumvent, (or attempt to do so), the security of our
              network, equipment, content, hardware or software.
            </p>
            <p className="one-level-margin">
              Not to copy, distribute, make available, attempt to disassemble, decompile,
              create derivative works of, reverse-engineer, modify, sub-license, or use
              for any other purposes any software or equipment we provide except:
            </p>
            <p className="two-level-margin">
              as follows respect of open source software:
            </p>
            <p className="two-level-margin">
              Certain components of the software for the equipment we provide to you and
              our application are subject to so-called open-source licences. This open
              source software is not subject to the restrictions in this paragraph but
              is subject instead to the terms of the applicable open source licence.
              You are free to use, modify and distribute open source software, so long
              as you comply with the terms of the applicable open source licence; and
            </p>
            <p className="two-level-margin">
              to make a back-up copy of the software we provide for your personal use.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>
              Not to access without authority, interfere with, damage or disrupt:
            </p>
            <p className="one-level-margin">any part of our site or application;</p>
            <p className="one-level-margin">any equipment or network on which our site is stored;</p>
            <p className="one-level-margin">any software used in the provision of our site or application; or</p>
            <p className="one-level-margin">any equipment or network or software owned or used by any third party.</p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>
              Not to use any of the services in a manner not consistent with reasonable
              residential domestic use;
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>
              Not to use the services in a way that:
            </p>
            <p className="one-level-margin">
              risks degradation of services to other customers;</p>
            <p className="one-level-margin">
              puts our network at risk;
            </p>
            <p className="one-level-margin">
              is not in keeping with that reasonably expected of a residential customer; and/or
            </p>
            <p className="one-level-margin">
              breaks the law or infringes the rights of any other person.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>
              The following acts are also not permitted:
            </p>
            <p className="one-level-margin">
              copying all or any part of our services except as may be permitted
              by law (and if this kind of copying becomes illegal in the future
              you must stop doing it); and/or
            </p>
            <p className="one-level-margin">
              re-selling, or making any charge using, all or any part of the services.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>
              <strong>Interactive services</strong>
            </p>
            <p>
              We may from time to time provide interactive services on our site
              or application, including, without limitation:
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>Chat rooms.</p>
            <p>Bulletin boards.</p>
            <p>Blogs</p>
            <p>Interactive services.</p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>
              Where we do provide any interactive service, we will provide clear
              information to you about the kind of service offered, if it is
              moderated and what form of moderation is used (including whether
              it is human or technical).
            </p>
            <p>
              We will do our best to assess any possible risks for users (and in
              particular, for children) from third parties when they use any
              interactive service provided on our site or application, and we
              will decide in each case whether it is appropriate to use
              moderation of the relevant service (including what kind of
              moderation to use) in the light of those risks. However, we are
              under no obligation to oversee, monitor or moderate any interactive
              service we provide on our site or application, and we expressly
              exclude our liability for any loss or damage arising from the use
              of any interactive service by a user in contravention of our content
              standards, whether the service is moderated or not.
            </p>
            <p>
              The use of any of our interactive services by a minor is subject
              to the consent of their parent or guardian. We advise parents who
              permit their children to use an interactive service that it is
              important that they communicate with their children about their
              safety online, as moderation is not foolproof. Minors who are
              using any interactive service should be made aware of the
              potential risks to them.
            </p>
            <p>
              Where we do moderate an interactive service, we will normally
              provide you with a means of contacting the moderator, should a
              concern or difficulty arise.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Do not rely on information on this site</strong></p>
            <p>
              The content on our site is provided for general information 
              only. It is not intended to amount to advice on which you should 
              rely. You must obtain professional or specialist advice before 
              taking, or refraining from, any action on the basis of the content 
              on our site.
            </p>
            <p>
              Although we make reasonable efforts to update the information 
              on our site, we make no representations, warranties or guarantees, 
              whether express or implied, that the content on our site is accurate, 
              complete or up to date.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>We are not responsible for websites we link to</strong></p>
            <p>
              Where our site contains links to other sites and resources 
              provided by third parties, these links are provided for your 
              information only. Such links should not be interpreted as approval by 
              us of those linked websites or information you may obtain from them.
            </p>
            <p>
              We have no control over the contents of those sites or resources.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Our responsibility for loss or damage suffered by you</strong></p>
            <p>
              We do not exclude or limit in any way our liability to you where it 
              would be unlawful to do so. This includes liability for death or personal 
              injury caused by our negligence or the negligence of our employees, agents 
              or subcontractors and for fraud or fraudulent misrepresentation.
            </p>
            <p>
              Different limitations and exclusions of liability will apply to 
              liability arising as a result of the supply of our services to you, 
              which will be set out in our business terms.
            </p>
            <p>
              Please note that we only provide our site for domestic and private use. 
              You agree not to use our site for any commercial or business purposes, 
              and we have no liability to you for any loss of profit, loss of business, 
              business interruption, or loss of business opportunity.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>How we may use your personal information</strong></p>
            <p>
              We will only use your personal information as set out in our Privacy Policy.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>We are not responsible for viruses and you must not introduce them</strong></p>
            <p>
              We do not guarantee that our site will be secure or free from bugs or viruses.
            </p>
            <p>
              You are responsible for configuring your information technology, computer 
              programmes and platform to access our site. You should use your own virus 
              protection software.
            </p>
            <p>
              You must not misuse our site by knowingly introducing viruses, trojans, worms, 
              logic bombs or other material that is malicious or technologically harmful. You 
              must not attempt to gain unauthorised access to our site, the server on which our 
              site is stored or any server, computer or database connected to our site. You must 
              not attack our site via a denial-of-service attack or a distributed denial-of 
              service attack. By breaching this provision, you would commit a criminal offence 
              under the Computer Misuse Act 1990. We will report any such breach to the relevant 
              law enforcement authorities and we will co-operate with those authorities by disclosing 
              your identity to them. In the event of such a breach, your right to use our site will 
              cease immediately.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Content standards</strong></p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>
              These content standards apply to any and all material which you
              contribute to our site or application (contributions), and to any
              interactive services associated with it.
            </p>
            <p>
              You must comply with the spirit of the following standards as well
              as the letter. The standards apply to each part of any
              contribution as well as to its whole.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>Contributions must:</p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p className="one-level-margin">Be accurate (where they state facts).</p>
            <p className="one-level-margin">Be genuinely held (where they state opinions).</p>
            <p className="one-level-margin">
              Comply with applicable law in the UK and in any country from which
              they are posted.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p>Contributions must not:</p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p className="one-level-margin">Contain any material which is defamatory of any person.</p>
            <p className="one-level-margin">
              Contain any material which is obscene, offensive, hateful or
              inflammatory.
            </p>
            <p className="one-level-margin">Promote sexually explicit material.</p>
            <p className="one-level-margin">Promote violence.</p>
            <p className="one-level-margin">
              Promote discrimination based on race, sex, religion, nationality,
              disability, sexual orientation or age.
            </p>
            <p className="one-level-margin">
              Infringe any copyright, database right or trade mark of any other
              person.
            </p>
            <p className="one-level-margin">Be likely to deceive any person.</p>
            <p className="one-level-margin">
              Be made in breach of any legal duty owed to a third party, such as
              a contractual duty or a duty of confidence.
            </p>
            <p className="one-level-margin">Promote any illegal activity.</p>
            <p className="one-level-margin">
              Be threatening, abuse or invade another’s privacy, or cause
              annoyance, nuisance inconvenience needless worry to, or
              needless anxiety or infringe the rights of any person.
            </p>
            <p className="one-level-margin">
              Be likely to harass, upset, embarrass, alarm or annoy any other
              person.
            </p>
            <p className="one-level-margin">
              Be used to impersonate any person, or to misrepresent your
              identity or affiliation with any person.
            </p>
            <p className="one-level-margin">
              Give the impression that they emanate from us, if this is not the
              case.
            </p>
            <p className="one-level-margin">
              Advocate, promote or assist any unlawful act such as (by way of
              example only) copyright infringement or computer misuse.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Rules about linking to our site</strong></p>
            <p>
              You may link to our home page, provided you do so in a way that
               is fair and legal and does not damage our reputation or take 
               advantage of it.
            </p>
            <p>
              You must not establish a link in such a way as to suggest any 
              form of association, approval or endorsement on our part where 
              none exists.
            </p>
            <p>
              You must not establish a link to our site in any website that 
              is not owned by you.
            </p>
            <p>
              Our site must not be framed on any other site, nor may you create 
              a link to any part of our site other than the home page.
            </p>
            <p>
              We reserve the right to withdraw linking permission without notice.
            </p>
            <p>
              If you wish to link to or make any use of content on our site other 
              than that set out above, please contact <a className="simple-link" href="mailto:support@voltaware.com">support@voltaware.com</a>.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Suspension and termination</strong></p>
            <p>
              We will determine, in our discretion, whether there has been a
              breach of this acceptable use policy through your use of our site,
              application, equipment or any of our services. When a breach of
              this policy has occurred, we may take such action as we deem
              appropriate.
            </p>
            <p>
              Failure to comply with this acceptable use policy constitutes a
              material breach of the terms of use{' '}
              <a
                className="simple-link"
                href="https://www.voltaware.com/terms-and-conditions"
              >
                https://www.voltaware.com/terms-and-conditions
              </a>{' '}
              {' '}and consumer standard terms{' '}
              <a
                className="simple-link"
                href="https://www.voltaware.com/consumer-standard-terms"
              >
                https://www.voltaware.com/consumer-standard-terms
              </a>
              {' '}
              and any other conditions where these terms are referred to upon
              which you are permitted to use our site, application, equipment
              or any of our services, and may result in our taking all or any
              of the following actions:
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p className="one-level-margin">
              Immediate, temporary or permanent withdrawal of your right to use
              our site, application, equipment or any of our services.
            </p>
            <p className="one-level-margin">
              Immediate, temporary or permanent removal of any posting or
              material uploaded by you to our site or application.
            </p>
            <p className="one-level-margin">Issue of a warning to you.</p>
            <p className="one-level-margin">
              Legal proceedings against you for reimbursement of all costs on an
              indemnity basis (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach.
            </p>
            <p className="one-level-margin">Further legal action against you.</p>
            <p className="one-level-margin">
              Disclosure of such information to law enforcement authorities as
              we reasonably feel is necessary.
            </p>
            <p>
              We exclude liability for actions taken in response to breaches of
              this acceptable use policy. The responses described in this policy
              are not limited, and we may take any other action we reasonably
              deem appropriate.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>We may suspend or withdraw our site</strong></p>
            <p>
              Our site is made available free of charge.
            </p>
            <p>
              We do not guarantee that our site, or any content on it, will 
              always be available or be uninterrupted. We may suspend or withdraw 
              or restrict the availability of all or any part of our site for 
              business and operational reasons. We will try to give you reasonable 
              notice of any suspension or withdrawal.
            </p>
            <p>
              You are also responsible for ensuring that all persons who access 
              our site through your internet connection are aware of this acceptable 
              use policy and other applicable terms and conditions, and that they 
              comply with them.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>We may transfer this agreement to someone else</strong></p>
            <p>
              We may transfer our rights and obligations under this acceptable 
              use policy to another organisation. We will always tell you in 
              writing if this happens and we will ensure that the transfer will 
              not affect your rights under the contract.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Changes to the acceptable use policy</strong></p>
            <p>
              We may revise this acceptable use policy at any time by amending
              this page. You are expected to check this page from time to time
              to take notice of any changes we make, as they are legally binding
              on you. Some of the provisions contained in this acceptable use
              policy may also be superseded by provisions or notices published
              elsewhere on our site.
            </p>
            <p>
              We may update and change out site from time to time to reflect 
              changes to our services, our user’s needs and our business priorities.
            </p>
          </div>
        </section>

        <section className="section container">
          <div className="col">
            <p><strong>Which country's laws apply to any disputes?</strong></p>
            <p>
              If you are a consumer, please note that this acceptable use policy, 
              their subject matter and their formation, are governed by English law. 
              You and we both agree that the courts of England and Wales will have 
              exclusive jurisdiction except that if you are a resident of Northern 
              Ireland you may also bring proceedings in Northern Ireland, and if you 
              are resident of Scotland, you may also bring proceedings in Scotland.
            </p>
          </div>
        </section>
        <section className="section container">
          <div className="col">
            <p><strong>Our trade marks are registered</strong></p>
            <p><img src="/img/acceptable-use-voltaware-name.jpg" alt="" width="250px" /></p>
            <p><img src="/img/acceptable-use-voltaware-logo.jpg" alt="" width="80px" /></p>
            <p>
              and "VOLTAWARE" are UK registered trade marks of Torro Ventures Limited, 
              our parent company. You are not permitted to use them without our approval, 
              unless they are part of material you are using as already permitted in 
              this acceptable use policy.
            </p>
          </div>
        </section>
      </section>
    );
  }
}

export default AcceptableUse;
