import React from 'react';

// Components
import Meta from '../components/meta.js';

class PrivacyPolicy extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      privacePolicy: ''
    };
  }

  componentDidMount() {
    fetch('/terms-and-conditions.json', { method: 'GET' }).then(res => {
      if (res.ok) {
        this.setState({ loading: false });
        res.json().then(data => {
          this.setState({ privacePolicy: data.policy });
        });
      }
    });
  }

  render() {
    return (
      <section id="privacy-policy">
        <Meta
          title="Privacy Policy"
          description="Review our Private Policy"
          url="https://www.voltaware.com/privacy-policy"
        />

        {this.state.loading ? (
          <img src="/img/loading.svg" alt="" />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: this.state.privacePolicy }} />
        )}
      </section>
    );
  }
}

export default PrivacyPolicy;
