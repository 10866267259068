import React from 'react';

// Components
import Meta from '../components/meta.js';

class ProductTerms extends React.Component {
  render() {
    return (
      <section id="product-terms">
        <Meta
          title="Product Terms"
          description="Review our Product terms"
          url="https://www.voltaware.com/product-terms"
        />

        <section className="section container">
          <div className="col">
            <h4 className="title no-border-bottom" style={{ borderBottom: 0 }}>
              Product Terms
            </h4>
          </div>
        </section>

        <section className="section container">
          <div className="col">
          <p>This page (together with the documents referred to on it) tells you the terms on which we will supply to you and the
              rights you have in respect of any sensor or other hardware that we supply to you (“Products”). These terms are in
              addition to terms and conditions which can be found by following this link <a class="simple-link"
                  href="https://voltaware.com/terms-and-conditions">https://voltaware.com/terms-and-conditions</a> (the “Terms
              &amp; Conditions”).</p>

          <p>You should print or save a copy of these product terms (the “Product Terms”) for future reference.</p>
          <p>References to “we”, “us” or “our” are references to Voltaware Services Limited. We are a limited company registered
              in England and Wales (company number 9844696), and our registered address is Heston Cross House, 230 High Street,
              Swanage, Dorset, United Kingdom BH19 2PQ and our address for correspondence is: 1st Floor, 282 Earls Court Road,
              London, SW5 9AS, UK. Our VAT number is GB251512047.</p>
          <p>References to “you” and “your” are to persons who purchases the Products (as defined below), to whom the Products are
              otherwise supplied and/or the person who uses the Products.</p>
          <p>By using the Products you agree to these Product Terms as set out below and to the Terms &amp; Conditions. If you do
              not agree to either these Product Terms or the Terms &amp; Conditions, you must stop using the Products immediately.
          </p>

          <p>Purchase Orders/Invoices and payment terms</p>

          <p>Prior to the delivery of the Products hereunder, either (1) you will have issued us with a purchase order or (2) we
              will have issued you with an invoice for the Products or (3) the Products may have been provided to you under the
              terms of another agreement or arrangement (any documents or arrangements referred to in (1) to (3) being referred to
              as a “PO”).</p>

          <p>Any PO shall be subject to these Product Terms and the Terms &amp; Conditions and you agree that any inconsistent or
              additional terms in the POs which are incorporated into such POs by reference shall have no force and effect. The
              POs will be effective solely with respect to prices and quantity of Products to be supplied, the delivery time and
              address and any terms which are individually negotiated and appear clearly on the face of the relevant PO and not
              other terms. Unless otherwise agreed, the price referred to in the PO shall be a total price covering shipping costs
              which we will have provided to you prior to its issuance.  </p>

          <p>If you are based outside the United Kingdom, you are responsible for any licences, consents or authorisations
              required to enable us to deliver the Products to you and for the use of the Products in your jurisdiction. The
              Product will include a reference to CEE certification. This is a certification relevant to the United Kingdom so if
              you are based outside the United Kingdom, you will have to check if this is sufficient in your jurisdiction.</p>

          <p>We shall issue an invoice against any purchase order received from you. The terms of payment under the invoice will
              be set out in the invoice or in another agreement forming part of the PO.  </p>

          <p>If the Products have been provided for evaluation prior to payment, then payment will be due within thirty (30) days
              from the end of the Evaluation Period in respect of any Products which are not returned to us. </p>

          <p>Any amounts payable to us are exclusive of any applicable value added tax, excise, sales taxes or levies of a similar
              nature which are imposed or charged by any competent fiscal authority in respect of the Products. You will be
              additionally liable to pay these to us.</p>

          <p>In case of non-payment or incomplete payment when due, we will charge interest at 5% per annum on any amount due but
              not paid until the date of payment.</p>

          <p>We may set-off any payment obligation owed to you under these Product Terms against any obligation (whether or not
              matured) owed us to you.</p>

          <p>Delivery </p>

          <p>The timing for delivery of the Products and the delivery address shall be stated in the PO. Unless otherwise agreed
              in advance, all Products shall be packed by us as we reasonably deem appropriate to minimize risk of loss or damage
              in transit.</p>

          <p>If  you fail to take delivery of the Products or any part of them on agreed delivery date and/or fail to provide any
              instructions, documents, licences, consents or authorisations required to enable the Products to be delivered on
              that date, we shall be entitled, upon giving written notice to you, to store or arrange for the storage of the
              Products and then the risk in the Products shall pass to the you, delivery shall be deemed to have taken place and
              you shall pay us all costs and expenses including storage and insurance charges arising from such failure.</p>

          <p>Installation/Activation</p>

          <p>You are solely responsible for installing the Products. You should ensure that the Products are installed by a
              qualified electrician. The Products are frequently supplied with an instruction manual. If you have received a
              Products without a printed manual, please follow this link for an electronic version: <a class="simple-link"
                  href="https://voltaware.com/support#videos-manuals">https://voltaware.com/support#videos-manuals</a>.</p>

          <p>You must follow our manual in the installation and use of the Products.</p>

          <p>To activate a sensor, you will need to download the relevant application from The App Store or The Google play store.
              You need to sign up and “provision” the sensor using the product key on your sensor. The sensor will try to connect
              to your WiFi network. After successfully connecting the sensor to the WiFi network, the sensor light will be green.
          </p>

          <p>Use and Licence </p>

          <p>We grant you a non-sublicensable, non-transferable, non-exclusive right during the period when you are permitted to
              use the Products under the Product Terms or the Terms &amp; Conditions (the “Term”) to use the Products for the
              purposes of managing home energy consumption or other purposes as may be expressly specified in the terms of any PO.
              The Products shall be used at residential addresses. The Products shall be used in accordance with the Products’
              accompanying documentation. If the Products have been provided to you for evaluation they are provided solely for
              testing purposes. The Products are not intended for manufacturing or commercial use.</p>

          <p>The rights granted to you hereunder are non-exclusive and nothing hereunder shall be deemed to limit our ability to
              enter into any type of agreement with any other party anywhere in the world.</p>

          <p>Restrictions</p>

          <p>You shall not (and shall not allow any third party to):</p>

          <ol>
              <li>
                  <p>(a) decompile, disassemble, or otherwise reverse engineer the Products or attempt to reconstruct
                      or discover any source code, underlying ideas, algorithms, file formats or programming interfaces of the
                      Products by any means whatsoever (except and only to the extent that applicable law prohibits or restricts
                      reverse engineering restrictions, and in such case you shall notify us in advance of its intent to reverse
                      engineer); </p>
              </li>
              <li>
                  <p>(b) distribute, sell, sublicense, rent, lease or use the Products (or any portion thereof) for
                      any purpose, including without limitation time sharing, hosting, service provider;  </p>
              </li>
              <li>
                  <p>(c) remove any product identification, proprietary, copyright or other notices contained in or on
                      the Products; </p>
              </li>
              <li>
                  <p>(d) modify any part of the Products or create a derivative work of any part of the Products;  
                  </p>
              </li>
              <li>
                  <p>(e) publicly disseminate performance information or analysis (including, without limitation,
                      benchmarks) from any source relating to the Products; or </p>
              </li>
              <li>
                  <p>(f) separate any software included in the Products from the Products themselves, or use any
                      software included with the products separately from the Products. </p>
              </li>
          </ol>

          <p>Ownership</p>

          <p>Notwithstanding anything to the contrary contained herein (except for the limited license rights expressly provided
              herein), until we receive payment in respect of the Products we and our suppliers have and will retain all rights,
              title and interest in the Products. Until then you shall ensure that the Products do not become subject to any lien
              or encumbrance, and shall not use the Products as collateral for any transaction.</p>

          <p>We (or members of our group) will in any event retain ownership in all patent rights, mask works, copyrights,
              trademarks, service marks, related goodwill, and confidential and proprietary information) in the Products; and all
              modifications to, and derivative works based upon, the Products.</p>

          <p>Evaluation terms</p>

          <p>If you have been supplied the Products for evaluation only, by the end of any agreed evaluation period (the
              “Evaluation Period”) you shall either (1) return those units of the Products that you have decided not to purchase
              to us, freight and insurance prepaid, to a location which we advise you or (2) notify us in writing (including per
              email) that you have decided to purchase all or some units of the Products. In the case of (2), if the terms are
              unclear from any PO associated with the evaluation, we will advise further on process once we receive your
              notification. In case Products are returned, those Products that have software which is not embedded in a hardware
              device when delivered, you will erase all copies of that software in its possession or control. If you do not return
              the Products, we may at our sole discretion and without prejudice to any other rights you may have under these
              Product Terms charge you the full list price for the Products, plus shipping. You will have no obligation to pay for
              the Products if they have been returned during the Evaluation Period.</p>

          <p>Force Majeure</p>

          <p>Neither you nor us shall be liable to the other, or be deemed to be in breach of these Purchase Terms, by reason of
              any delay in performing, or failure to perform, any of our respective obligations under these Purchase Terms if the
              delay or failure was beyond our or your reasonable control (including without limitation fire, flood, explosion,
              epidemic, riot, civil commotion, any strike, lockout or other industrial action, act of God, war, warlike
              hostilities or threat of war, terrorist activities, accidental or malicious damage and any prohibition or
              restriction by any government or other legal authority which affects these Product Terms and which were not in force
              on the date when the relevant PO was entered into.</p>

          <p>If you or us claim to be unable to perform our respective obligations under these Product Terms (either on time or at
              all) in any of the circumstances set out in the paragraph above, that person shall notify the other of the nature
              and extent of the circumstances in question as soon as practicable.</p>

          <p>This section (Force Majeure) shall cease to apply when such circumstances have ceased to have effect on the
              performance of these Product Terms and the person affected shall give notice to the other person that the
              circumstances have ceased. If any circumstance relied on by a person for the purposes of this section (Force
              Majeure) continues for more than three months, the other person shall be entitled to terminate the agreement formed
              by these Product Terms.</p>

          <p>Termination</p>

          <p>The termination of the service associated with the use of the Products is regulated by the Terms &amp; Conditions.
              Note in particular that the Products are generally used in connection with the provision of the Voltaware Service
              and that is generally dependent on the payment of a subscription. Any rights that you have under these Product Terms
              will be terminated at the same time as the termination of the service associated with the use of the Products.</p>

          <p>We may terminate any of your rights under these Product Terms immediately upon notice upon any material breach of the
              Product Terms or the Terms &amp; Conditions by you. </p>

          <p>If Products have been provided for evaluation only and you have not yet paid for them, we may terminate your rights
              under these Product Terms during the Evaluation Period on 15 days’ notice to you without cause and you can return
              the Products at any time during the Evaluation Period. </p>

          <p>The sections of these Product Terms which are capable of surviving the termination of your rights, such as the
              sections titled “Restrictions”, “Ownership”, “Limitation of Remedies and Damages”, “Indemnity”, “Confidential
              Information” and “Other” shall continue in full force and effect after such termination. </p>

          <p>Warranty </p>

          <p>As regards any Products provided to you for evaluation, prior to you purchasing such Products, these are provided to
              you with no warranty whatsoever, express, implied or statutory, including, but not limited to warranties of title,
              non-infringement, merchantability or fitness for a particular purpose. is provided prior to you purchasing such
              Products. Once the Products have been purchased, the warranty referred to below shall apply. In any event, you
              acknowledge that the Products provided for evaluation may not work properly or in accordance with their
              documentation. You may have other statutory rights. However, to the full extent permitted by law, the duration and
              scope of statutorily required warranties, if any, shall be limited to the maximum extent permitted.</p>

          <p>As regards any Products that you have paid for, we warrant that any such Product will be free of manufacturing or
              mechanical defects unconnected with improper use for a period of 12 months from the date of delivery of such Product
              to you. </p>

          <p>In any event we do not warrant that your use of the Products will be uninterrupted or error-free or that any security
              mechanisms implemented by the Products will not have inherent limitations. You are solely responsible for
              determining whether the Products are suitable for any purposes for which they are intended to use.  Sales
              literature, price lists and other documents issued by us in relation to the Products are subject to alteration
              without notice and do not constitute offers to sell the Products which are capable of acceptance. Illustrations,
              photographs or descriptions whether in catalogues, brochures, price lists or other documents issued by us are
              intended as a guide only and shall not be binding on us.  Any typographical, clerical or other accidental errors or
              omissions in any sales literature, quotation, price list, acceptance of offer, invoice or other document or
              information issued by us shall be subject to correction without any liability on our part.</p>

          <p>The specification for the Products shall be in accordance with their description in the PO (the “Specification”). We
              reserve our right to make any changes in the Specification of the Products which are required to conform with any
              applicable safety or other statutory or regulatory requirements or, where the Products are to be supplied to your
              specification, which do not materially affect their quality or performance. If you object to any Specification
              change proposed by us, then the Parties will consult in good faith to resolve their differences and the Supplier
              will not implement the change without Purchaser’s consent (not to be unreasonably withheld). The Purchaser may
              request a variation of the Specification expressly in an order. Such variations may be accepted by the Supplier at
              its discretion (such consent not to be unreasonably withheld).  This promise is referred to in these Product Terms
              as the “Warranty”.</p>

          <p>If you allege that any Product is defective, you shall, if so requested by us, return the relevant Product to us for
              inspection as soon as possible and at its own risk and expense.</p>

          <p>If you reject any delivery of the Product which does not comply with the requirements this section we shall, within
              45 days of us accepting that the relevant Product does not comply with the Warranty:</p>

          <p>(a)<span> supply replacement Products which comply with the Warranty; or</span></p>

          <p>(b)<span> notify you that we are unable to supply the replacement Product(s) and we shall grant to you a credit equal
                  to the value of the Product(s) which the Supplier agrees do not comply with the Warranty. </span></p>

          <p>Our reasonable decision as to whether the Products comply with the Warranty shall be final.</p>

          <p>This section does not affect your statutory rights.</p>

          <p>Limitation of Remedies and Damages</p>

          <p>We shall not be liable for any loss of use, lost data, failure of security mechanisms, interruption of business, or
              any indirect, special, incidental, or consequential damages of any kind (including lost profits), regardless of the
              form of action, whether in contract, tort (including negligence), strict liability or otherwise, even if informed of
              the possibility of such damages in advance. </p>

          <p>Notwithstanding any other provision of these Product Terms or the Terms &amp; Conditions, our entire liability to you
              in relation to the Products shall not exceed the standard list price of the Products.</p>

          <p>Nothing in these Product Terms excludes or limits our liability for death or personal injury arising from our
              negligence, nor our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor
              any other liability which cannot be excluded or limited under applicable law.</p>
          <p>Indemnity </p>

          <p>You shall indemnify and hold us harmless from and against any and all costs, damages, losses, liability or expenses
              (including reasonable attorneys’ fees) arising from your use of the Products in breach of these Product Terms or of
              the Terms &amp; Conditions (including without limitation any actions arising from acts or omissions your employees
              or agents) or any failure by you to comply with these Product Terms or of the Terms &amp; Conditions.</p>

          <p>Confidential Information</p>

          <p>You agree that all code, inventions, know-how, business, technical and financial information you obtain regarding the
              Products constitute our confidential property (“Confidential Information”). </p>

          <p>You will hold in confidence and not use or disclose any Confidential Information except as expressly permitted in
              these Product Terms. Your non-disclosure obligation shall not apply to information which you can document:</p>

          <ol>
              <li>
                  <p><span>(i)</span>is or has become public knowledge through no fault of yours; or </p>
              </li>
              <li>
                  <p><span>(ii)</span>is required to be disclosed pursuant to a regulation, law or court order (but only to the
                      minimum extent required to comply with such regulation or order and with advance notice to you).  </p>
              </li>
          </ol>

          <p>You acknowledge that disclosure of Confidential Information would cause substantial harm to us that could not be
              remedied by the payment of damages alone, and therefore that upon any such disclosure by you, we shall be entitled
              to seek appropriate equitable relief.  You will return all of our Confidential Information to us upon termination or
              expiration of the Term and, if requested by us at any later time, certify to such return in writing.</p>


          <p>Other </p>

          <p>These Product Terms shall be governed by and construed under the laws of England and you submit to the jurisdiction
              of English courts to resolve any disputes  (including any non-contractul disputes) in connection with these Product
              Terms or the use of the Products. We retain the right to bring proceedings against you for breach of these Product
              Terms in your country of residence or any other relevant country.</p>

          <p>The parties are independent contractors and no employment, agency, or joint venture is created hereunder. </p>

          <p>All notices, requests and other communications under these Product Terms must be in writing, and must be mailed by
              registered or certified mail, postage prepaid and return receipt requested, or delivered by hand to the party to
              whom such notice is required or permitted to be given. Headings and titles are for convenience only, and are not
              intended to be of operative effect or modify the terms of these Product Terms. </p>

          <p>The Product Terms may not be assigned by you. We may amend these Product Terms without your written consent. Any
              purported assignment or amendment in violation of the foregoing shall be void. </p>

          <p>If any provision of these Product Terms is held by a court of competent jurisdiction to be unenforceable for any
              reason, the remaining provisions hereof shall be unaffected and remain in full force and effect. </p>

          <p>These Product Terms are the final and complete agreement between the parties relating to the subject matter hereof,
              and supersede all prior or contemporaneous understandings and agreements relating to such subject matter, whether
              oral or written.</p>
          </div>
        </section>
      </section>
    );
  }
}

export default ProductTerms;
