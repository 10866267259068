import React from 'react';

import Meta from '../components/meta.js';

const OurTechnologyVoltawareSensors = () => (
  <section className="__voltaware-sensors-wrap">
    <Meta
      title="Data Collection - Voltaware sensors"
      description="Voltaware energy monitoring sensors install at home in 5 min and collect high resolution electricity data."
      url="https://www.voltaware.com/our-technology/voltaware-sensors"
    />
    <section className="__voltaware-sensors-header">
      <h1>Voltaware Sensors</h1>
    </section>
    <section className="__voltaware-sensors-section" id="presenting-sensor">
      <figure><img src="/img/technology/voltaware-sensor-inclined.png" alt="Voltaware 1 Phase Sensor"/></figure>
      <article className="__presenting-sensor-wrap">
        <h2>Credit card-sized.</h2>
        <h2>Ultra-high resolution data.</h2>
        <h2>5 min installation.</h2>
      </article>
    </section>
    <section className="__voltaware-sensors-section" id="only-of-its-kind">
      <h2>The only one of its kind</h2>
      <section className="__only-of-its-kind-wrap">
        <figure><img src="/img/technology/voltaware-sensor-standing.png" alt="Voltaware 1 Phase Sensor"/></figure>
        <article className="__article-wrap">
          <p>Today, most electricity sensors aren’t future-proof - they are cumbersome and require hardwiring.</p>
          <p>Voltaware offers the only solution on the market which can be installed non-intrusively in under 5 minutes, providing highly detailed insights into your home’s electrical events.</p>
          <p>Ultra-slim and credit card-sized, Voltaware constantly samples voltage and current signals from the principal cable carrying current through your home at a rate of more than 10,000 times a second.</p>
        </article>
      </section>
    </section>
    <section className="__voltaware-sensors-section" id="cutting-edge-technology">
      <h2>Cutting-edge sensor technology</h2>
      <section className="__cutting-edge-technology-wrap">
        <figure><img src="/img/technology/voltaware-sensor-installed.png" alt="Voltaware Sensor Installed"/></figure>
        <section className="__article-wrap">
          <article className="__cutting-edge-technology-article">
            <h3>Plug & Play</h3>
            <p>You can install and uninstall Voltaware and begin monitoring different properties in a matter of minute. Connect it to the local Wi-Fi and you are ready to go.</p>
          </article>
          <article className="__cutting-edge-technology-article">
            <h3>Future-proof</h3>
            <p>Voltaware sensors can be remotely upgraded to send data at different intervals and adapt to any situation - allowing for maximum flexibility.</p>
          </article>
          <article className="__cutting-edge-technology-article">
            <h3>Patented technology</h3>
            <p>Voltaware has obtained global patents for its innovative magnetic connectors around the hardware, providing durable competitive advantages.</p>
          </article>
          <article className="__cutting-edge-technology-article">
            <h3>Smart meter-grade accuracy</h3>
            <p>Voltaware sensors are built from the same technology as typical smart meters operating with a 1% error margin.</p>
          </article>
        </section>
      </section>
    </section>
    <section className="__voltaware-sensors-section" id="easy-instalattion">
      <h2>Easy installation</h2>
      <section className="__easy-installation-wrap">
        <figure><img src="/img/technology/voltaware-sensor-easy-installation.png" alt="Easy Installation"/></figure>
        <article className="__article-wrap">
          <p>Our ultra-compact design means the Voltaware sensor can be installed easily into your electrical fuse box you only need one sensor per home.</p>
          <p>Its magnetic probes connect to the circuit breaker to sample voltage while a clamp connects to the main current cable. Voltaware is event-based, which means it can detect any step in the current.</p>
          <p>Voltaware is DIN rail mountable and compatible with all types of fuseboxes. It seamlessly connects to your Wi-Fi to send high resolution electricity data.</p>
        </article>
      </section>
    </section>
    <section className="__voltaware-sensors-section" id="compatibility">
      <h2>Compatibility</h2>
      <h3 className="dark-grey">Voltaware is available in two models:</h3>
      <section className="__section-row">
        <figure className="__single-phase-sensor"><img src="/img/technology/voltaware-sensor.png" alt="Voltaware Sensor Single-Phase Wi-Fi"/></figure>
        <article className="__article-wrap">
          <h3>Single-Phase Wi-Fi</h3>
          <ul>
            <li>Operating voltage: 85 - 250 V ac</li>
            <li>Operating frequency: 50 - 60 Hz</li>
            <li>Power consumption (typical): 1 W</li>
            <li>Connectivity: IEEE 802.11n/g/b 2.4 GHz</li>
            <li>Supported CT: 1x 30A / 50A</li>
            <li>Maximal power measured: 7.5/15/25 kW</li>
            <li>Case dimensions: 83 × 56 × 7 mm</li>
            <li>Mounting: Top-hat rail EN 50022 – 35 × 15</li>
          </ul>
        </article>
      </section>
      <section className="__section-row">
        <figure className="__three-phase-sensor"><img src="/img/technology/voltaware-sensor-3-phase-standing.png" alt="Voltaware Sensor Three-Phase Wi-Fi"/></figure>
        <article className="__article-wrap">
          <h3>Three-Phase Wi-Fi</h3>
          <ul>
            <li>Operating Voltage: 85 - 250 V ac</li>
            <li>Operating Frequency: 50-60 Hz</li>
            <li>Power consumption (typical): 1 W</li>
            <li>Connectivity: IEEE 802.11 n/g/b 2.4 GHz</li>
            <li>Supported CT: 3x 100 A/phase</li>
            <li>Case dimensions: 87 × 56 × 16 mm</li>
            <li>Mounting: Top-hat rail EN 50022 – 35 × 15</li>
          </ul>
        </article>
      </section>
    </section>
  </section>
);

export default OurTechnologyVoltawareSensors;
