import React from 'react';

const Awards = () => (
  <section className="awards">
      <div className="box">
        <img src="/img/awards/brazil-tech-award.png" alt="Brazil Tech Award" />
        <img src="/img/awards/SET100_2020.png" alt="SET100 2020" />
        <img src="/img/awards/advancing-low-carbon.png" alt="Advancing Low Carbon" />
        <img src="/img/awards/clean-equity-monaco-2021.jpeg" alt="Clean Equity Monaco 2021" />
        <img src="/img/awards/solar-impulse-sept-2022.png" alt="Solar Impulse Award - September 2022" />
      </div>
  </section>
);

export default Awards;
