import React from 'react';

// Components
import Meta from '../components/meta.js';

class ContactUs extends React.Component {
  render() {
    return (
      <section id="contact-us">
        <Meta
          title="Contact us"
          description="Find out where to contact us."
          url="https://www.voltaware.com/contact-us"
        />

        <section className="contact-us-map">
          <iframe
            allowFullScreen=""
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.2065772519563!2d-0.19399398433109624!3d51.49107651971778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760f8ba71a5fd7%3A0x456127b6c19f7527!2s282+Earls+Ct+Rd%2C+Kensington%2C+London+SW5+9AS%2C+UK!5e0!3m2!1sen!2sin!4v1455892247097"
            title="Contact us map"
          ></iframe>
        </section>

        <section className="section container" id="contact">
          <div className="col contact-us-contact">
            <h3 className="title">
              <span>Address</span>
            </h3>
            <strong>United Kingdom:</strong>
            <p>1st Floor of 282 Earls Court Road, London</p>
            <p>SW5 9AS</p>
            <br />
            <strong>Italy:</strong>
            <p>Voltaware Europe, PoliHub, Via Giovanni Durando 39</p>
            <p>20158 Milano</p>
            <br />
            <strong>Spain:</strong>
            <p>WeWork, Paseo de la Castellana 77</p>
            <p>28046 Madrid</p>
            <br />
            <p>
              <a href="mailto:enquiries@voltaware.com">
                enquiries@voltaware.com
              </a>
            </p>
          </div>
        </section>
        <section className="section container" id="support">
          <div className="col contact-us-contact">
            <h3 className="title">
              <span>Support</span>
            </h3>
            <p>
              If you did not find what you need on the SUPPORT page, please
              email us at
            </p>
            <p>
              <a href="mailto:support@voltaware.com">support@voltaware.com</a>
            </p>
          </div>
        </section>
        <section className="section container" id="work">
          <div className="col contact-us-contact">
            <h3 className="title">
              <span>Work for us</span>
            </h3>
            <p>
              If you are interested in applying for a position with Voltaware,
              please send your CV and covering letter to{' '}
              <a href="mailto:elena@voltaware.com">elena@voltaware.com</a>,
              indicating which office you will be willing to work for.
            </p>
          </div>
        </section>
      </section>
    );
  }
}

export default ContactUs;
