import React from 'react';

const ShortBanner = ({ title, image }) => {
  return (
    <section
      className="short-banner"
      style={{ backgroundImage: `url(/img/${image})` }}
    >
      <div className="short-banner-wrap">
          <h1>{title}</h1>
      </div>
    </section>
  );
};

export default ShortBanner;
