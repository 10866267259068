import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="__footer">
    <section className="__nav-wrap">
      <section className="__nav-column">
        <ul>
          <li>
            <Link to="/team/about-us">About us</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact us</Link>
          </li>
          <li>
            <Link to="/request-a-demo">Request a demo</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
      </section>
      <section className="__nav-column">
        <ul>
          <li>
            <a
              href="https://volta-monitoring.com/"
              title="For business"
              target="_blank"
              rel="noopener noreferrer"
            >
              For business
            </a>
          </li>
          <li>
            <Link to="/support">FAQ</Link>
          </li>
        </ul>
      </section>
      <section className="__nav-column">
        <ul>
          <li>
            <Link to="/acceptable-use">Acceptable use</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </li>
        </ul>
      </section>
      <section className="__nav-column">
        <ul className="__inline">
          <li>
            <a href="https://wa.me/+447518851713" target="_blank" rel="noopener noreferrer">
              <figure><img src="/img/whatsapp-icon.svg" alt="Voltaware on Whatsapp"/></figure>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/voltaware" target="_blank" rel="noopener noreferrer">
              <figure><img src="/img/twitter-w.svg" alt="Voltaware on Twitter"/></figure>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/voltaware" target="_blank" rel="noopener noreferrer">
              <figure><img src="/img/linkedin-w.svg" alt="Voltaware on Linkedin"/></figure>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/voltaware/" target="_blank" rel="noopener noreferrer">
              <figure><img src="/img/facebook-w.svg" alt="Voltaware on Facebook"/></figure>
            </a>
          </li>
        </ul>
      </section>
    </section>
    <section className="last-section">
      <a className="email" href="mailto:enquires@voltaware.com" target="_blank" rel="noopener noreferrer">
        <img className="voltaware-logo" src="/img/mail-icon.svg" alt=""/> enquires@voltaware.com
      </a>
      <img className="voltaware-logo" src="/img/voltaware-white-logo.svg" alt="Voltaware Logo"/>
      <img className="amazon-logo" src="/img/technology/amazon-logo.svg" alt="Amazon Logo"/>
    </section>
    <p>© Voltaware {(new Date()).getFullYear()}</p>
  </footer>
);

export default Footer;
