import React from 'react';

import Meta from '../components/meta.js';

const AmazonShop = () => (
  <section className="__amazon-shop-wrap">
    <Meta
      title="Amazon Shopping"
      description="Voltaware is now available on Amazon."
      url="https://www.voltaware.com/amazon-shop"
    />
    <section>
      <article className="__amazon-shop-header">
        <div className="mobile"><img src="/img/technology/header-phone.png" alt="Voltaware Home app" /></div>
        <div className="elements">
          <h1>The smart<br/> way to save<br/> on energy</h1>
          <div className="phone"><img src="/img/technology/header-phone.png" alt="Voltaware Home app" /></div>
          <div className="amazon-logo"><img src="/img/technology/amazon-logo.svg" alt="Amazon Logo" /></div>
        </div>
      </article>
    </section>

    <section className="strip">
      <article className="__amazon-shop-section">
        <div className="voltaware-home-wrapper">
          <img className="voltaware-home" src="/img/technology/voltaware-home-sensor.png" alt="Voltaware Home" />
        </div>
        <div className="voltaware-home-action-wrapper">
          <h2>Voltaware has launched its revolutionary Home Energy Monitor available for all UK homes</h2>
          <p className="purchase">Now available for</p>
          <p className="price">£149.99 <span>£199.99!</span></p>
          <a
            href="https://www.amazon.co.uk/Voltaware-Actionable-Automatic-Recognition-Installation/dp/B0CLVBSZ4Y"
            rel="noopener noreferrer"
            target="_blank"
            className="order-btn btn-contained">
            Order now on
            <img src="/img/technology/amazon-logo.svg" alt="Amazon Logo" />
          </a>
        </div>
      </article>
    </section>

    <section>
      <article className="__features-section">
        <div className="feature">
          <figure><img src="/img/technology/voltaware-home-screen1.png" alt="Energy Efficiency screen" /></figure>
          <strong>Energy Efficiency</strong>
          <p>Optimise your energy use through appliance-level insights.</p>
        </div>
        <div className="feature">
          <figure><img src="/img/technology/voltaware-home-screen2.png" alt="Real-time monitoring screen" /></figure>
          <strong>Real-time monitoring</strong>
          <p>Track your home's electricity use in real-time, from anywhere.</p>
        </div>
        <div className="feature">
          <figure><img src="/img/technology/voltaware-home-screen3.png" alt="Sustainability & Netzero screen" /></figure>
          <strong>Sustainability & Netzero</strong>
          <p>Cut wasted energy with personalised tips & recommendations.</p>
        </div>
      </article>
    </section>

    <section>
      <article className="__how-it-works-section">
        <h2>How does it work?</h2>
        <div className="content">
          <img src="/img/technology/energy-disaggregation-chart.svg" alt="How Voltaware works" />
          <p>Each home appliance has a unique electronic fingerprint.</p>
          <p>Voltaware's AI algorithms detect the energy use of your main appliances in real-time. Allowing you to understand and optimise your energy use while reducing your environmental impact.</p>
          <p>Your sensor will start recording your appliance use in the Voltaware Home app moments after installation and activation.</p>
        </div>
      </article>
    </section>

    <section>
      <article className="__easy-installation-section">
        <h2>Easy Installation</h2>
        <div className="content">
          <img src="/img/technology/voltaware-sensors.png" alt="Voltaware Sensors" />
          <ul>
            <li>Wi-fi enabled</li>
            <li>Over the top clamp in fuse box on Installation</li>
            <li>Compatible with all standard UK homes</li>
          </ul>
        </div>
      </article>
    </section>

  </section>
);

export default AmazonShop;
