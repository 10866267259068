import React, { Component } from 'react';

class PartnersInvestors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: [
        {
          name: 'Plenitude',
          img: 'plenitude.svg',
          link: 'https://eniplenitude.com/',
          id: 'plenitude'
        },
        {
          name: 'Utilita',
          img: 'utilita.svg',
          link: 'https://utilita.co.uk/',
          id: 'utilita'
        },
        {
          name: 'Octopus Energy',
          img: 'octopus.png',
          link: 'https://octopus.energy/',
          id: 'octopus-energy'
        },
        {
          name: 'Burgenland Energie',
          img: 'burgenland.png',
          link: 'https://www.burgenlandenergie.at/',
          id: 'burgenland-energy'
        },
        {
          name: 'Krone Sonne',
          img: 'krone-sonne.png',
          link: 'https://kronesonne.at/',
          id: 'krone-sonne'
        },
        {
          name: 'BP',
          img: 'bp.png',
          link: 'https://www.bp.com/',
          id: 'bp',
          reduced: true,
        },
        {
          name: 'nrg',
          img: 'nrg.svg',
          link: 'https://www.nrg.gr/en',
          id: 'nrg'
        },
        {
          name: 'Regalgrid',
          img: 'regalgrid.svg',
          link: 'https://regalgrid.com/',
          id: 'regalgrid'
        },
        {
          name: 'Energit',
          img: 'energit.svg',
          link: 'https://energit.it/',
          id: 'energit'
        },
        {
          name: 'Ilek',
          img: 'ilek.png',
          link: 'https://www.ilek.fr/',
          id: 'ilek'
        },
        {
          name: 'Enel X',
          img: 'enel-x_purple.png',
          link: 'https://www.enelx.com/',
          id: 'enelx'
        },
        {
          name: 'Modstrøm',
          img: 'modstrom.svg',
          link: 'https://www.modstroem.dk/',
          id: 'modstrom'
        },
      ],
      investors: [
        {
          name: 'First Imagine!',
          img: 'firstimagine.png',
          link: 'http://www.firstimagine.com/',
          id: 'firstimagine'
        },
        {
          name: 'BP',
          img: 'bp.png',
          link: 'https://www.bp.com/',
          id: 'bp'
        },
      ]
    };
  }

  render() {
    const { partners, investors } = this.state;
    const showInvestors = this.props.showInvestors ?? true;

    return (
      <section className="partners-investors">
        <div className="container">
          <div className="partners-investors-content">
            <div className="content">
              <div className="box" data-title="Our partners include">
                {partners.map((partner, i) => (
                  <a
                    key={partner.id}
                    href={partner.link}
                    className={partner.reduced || !showInvestors ? "reduced-width-logo" : ""}
                    target="_blank"
                    title={partner.name}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`/img/partners/${partner.img}`}
                      alt={partner.name}
                    />
                  </a>
                ))}
              </div>
              {showInvestors && <div className="box" data-title="Our investors">
                {investors.map((investor, i) => (
                  <a
                    key={investor.id}
                    href={investor.link}
                    target="_blank"
                    title={investor.name}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`/img/partners/${investor.img}`}
                      alt={investor.name}
                    />
                  </a>
                ))}
              </div>}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PartnersInvestors;
