import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Meta from '../components/meta.js';

const RequestADemo = () => {
  const initialFields = {
    name: '',
    email: '',
    company: '',
    message: ''
  };

  const { addToast } = useToasts();

  const toast = (message, appearance = 'success') => addToast(message, { appearance, autoDismiss: true, placementType: 'bottom-center' });
  
  const [loading, setLoading] = useState(false);
  const [emailFields, setEmailFields] = useState(initialFields);
  const [validationError, setValidationError] = useState(false);

  const resetForm = () => setEmailFields(initialFields);

  const handleFormSubmit = e => {
    e.preventDefault();

    sendEmail();
  };

  const handleOnChange = name => (e) => {
    e.preventDefault();

    setEmailFields({ ...emailFields,  [name]: e.target.value });
  };

  const emailSuccess = () => {
    toast('Thank you.\nA member of our team will be in touch.', 'success');
  }

  const emailFailure = () => {
    toast('An error has occurred.\nPlease try again later or contact us directly at enquiries@voltaware.com', 'error');
  }

  const sendEmail = () => {
    if(emailFields.name === '' || emailFields.email === '' || emailFields.company === '' || emailFields.message === '') {
      const element = document.querySelector(".__form-wrap");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
      return setValidationError(true);
    }

    setLoading(true);

    const data = {
      from: `${emailFields.name} <${emailFields.email}>`,
      subject: "New enquiry from Voltaware's website",
      text: `Name: ${emailFields.name}
Email: ${emailFields.email}
Company: ${emailFields.company}
Message: ${emailFields.message}
`
    };

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch('/.netlify/functions/send-email', config)
      .then(res => {
        setLoading(false);
        setValidationError(false);

        if (res.ok) {
          resetForm();

          return emailSuccess();
        }

        return emailFailure();
      })
      .catch(err => {
        setLoading(false);
        setValidationError(false);

        return emailFailure();
      });
  }
  
  return (
    <section className="__request-a-demo-wrap">
      <Meta
        title="Request A Demo"
        description="If you would like to test our services, please request a demo here."
        url="https://www.voltaware.com/request-a-demo"
      />
      <section className="__request-a-demo-header">
        <h1>REQUEST A DEMO</h1>
      </section>
      <section className="__request-a-demo-section" id="discover-iris">
        <h2>Discover IRIS</h2>
        <p>IRIS by Voltaware uses AI to help utilities build new energy products and boost customer engagement.</p>
        <p>If you would like to find out more about our services, please fill in the form below to request a demo. Our team will be in contact with you shortly to address your needs.</p>
        <section className={`__form-wrap${loading ?  ' __loading' : ''}`}>
          <figure><img src="/img/demo/request-a-demo-chart.png" alt="Voltaware IRIS"/></figure>
          <form className="__demo-form" action="#" onSubmit={handleFormSubmit}>
            <section className="__form-row">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" value={emailFields.name} onChange={handleOnChange("name")} className={validationError && emailFields.name === "" ? "__form-validation-error" : ""}/>
            </section>
            <section className="__form-row">
              <label htmlFor="email">E-mail</label>
              <input type="email" name="email" id="email" value={emailFields.email} onChange={handleOnChange("email")} className={validationError && emailFields.email === "" ? "__form-validation-error" : ""}/>
            </section>
            <section className="__form-row">
              <label htmlFor="company">Company</label>
              <input type="text" name="company" id="company" value={emailFields.company} onChange={handleOnChange("company")} className={validationError && emailFields.company === "" ? "__form-validation-error" : ""}/>
            </section>
            <section className="__form-row">
              <label htmlFor="message">Enter your message</label>
              <textarea 
                name="message"
                id="message"
                value={emailFields.message}
                onChange={handleOnChange("message")}
                className={validationError && emailFields.message === "" ? "__form-validation-error" : ""}>
              </textarea>
            </section>
            <p>By submitting this form, you allow us to contact you by email. You can consult our <Link to="terms-and-conditions">Terms & Conditions</Link> and <Link to="privacy-policy">Private Policy</Link> below.</p>
            <div className="__form-actions">
              <input className="btn-outlined" type="submit" value="Submit Form"/>
            </div>
          </form>
        </section>
      </section>
      <section className="__request-a-demo-section" id="contact-us">
        <h2>Contact us</h2>
        <section className="__contact-cards">
          <section className="__card">
            <h3>United Kingdom</h3>
            <p>1st Floor of 282 Earls Court Road, London - SW5 9AS</p>
          </section>
          <section className="__card">
            <h3>Spain</h3>
            <p>WeWork, Paseo de la Castellana 77 - 28046 Madrid</p>
          </section>
          <section className="__card">
            <h3>Italy</h3>
            <p>Voltaware Europe, PoliHub, Via Giovanni Durando 39 20158 Milano</p>
          </section>
        </section>
      </section>
      <section className="__request-a-demo-section" id="follow-us">
        <h2>Follow us</h2>
        <div className="__nav-bar">
          <Link className="__nav-ico" to="blog">
            <figure><img src="/img/demo/blog.png" alt="Volta Blog"/></figure>
          </Link>
          <a className="__nav-ico" href="https://www.facebook.com/voltaware" target="_blank" rel="noopener noreferrer">
            <figure><img src="/img/demo/facebook.png" alt="Facebook"/></figure>
          </a>
          <a className="__nav-ico" href="https://twitter.com/voltaware" target="_blank" rel="noopener noreferrer">
            <figure><img src="/img/demo/twitter.png" alt="Twitter"/></figure>
          </a>
          <a className="__nav-ico" href="https://www.linkedin.com/company/voltaware" target="_blank" rel="noopener noreferrer">
            <figure><img src="/img/demo/linkedin.png" alt="Linkedin"/></figure>
          </a>
        </div>
      </section>
    </section>);
}

export default RequestADemo;
