import React from 'react';
import {withRouter} from 'react-router-dom';

class FaqItem extends React.Component {
  state = {
    selectedQuestion: null
  }

  componentDidMount(){
    const {location, items} = this.props;
    const hash = location && location.hash && location.hash.replace('#', '');
    if (hash){
      const found = items.find(item => this.transformQuestionId(item.question) === hash);
      if (found)
        this.toggle(found.question);
    }
  }

  toggle = (question, e) => {
    if (e)
      e.preventDefault();
    this.setState({selectedQuestion: question === this.state.selectedQuestion ? null : question});
  };

  transformQuestionId = (question) => {
    return question.toLocaleLowerCase().replace(/ /g, '-').replace(/'|"|\(|\)|\.|,|\/|\?/g, '').replace(/\n/g, '');
  }

  render() {
    const {selectedQuestion} = this.state;

    return (
      <div className="faq-item">
        <h4 className="faq-title">
          {this.props.title}
        </h4>
        <div className="faq-content">
          {!!this.props.observation && (
            <p dangerouslySetInnerHTML={{ __html: this.props.observation }}></p>
          )}
          <ul>
            {this.props.items.map((obj, i) => {
              return (
                <li key={i} id={this.transformQuestionId(obj.question)} className={obj.question === selectedQuestion ? 'active' : ''}>
                  <div className="question">
                    <img src="/img/bullet-arrow.svg" alt="list bullet" />
                    <button
                      type="button"
                      onClick={e => this.toggle(obj.question, e)}
                    >
                      {obj.question}
                    </button>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: obj.answer }} className="answer"></p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(FaqItem);
