import React from 'react';
import ReadMoreReact from 'read-more-react';

const Member = ({ member, style = {} }) => (
  <div className="about-member">
    <div className="about-member-img">
      <img src={member.img.src} alt={member.img.alt} style={style} />
    </div>

    <div className="about-member-info">
      <div className="member-role">
        <span>{member.role}</span>
      </div>
      <div className="member-name">{member.name}</div>
      <div className="member-about">
        <ReadMoreReact
          text={
            member.about.props
              ? member.about.props.defaultMessage
              : member.about
          }
          min={80}
          ideal={100}
          max={120}
          readMoreText="read more"
        />
      </div>
    </div>
  </div>
);

export default Member;
