import React, { Component } from 'react';

// Components
import Meta from '../components/meta.js';
import BlogListItem from '../components/blog-list-item.js';
import { BlogPosts } from './blog/blog-posts.js';

class Blog extends Component {
  render() {
    return (
      <div id="blog">
        <Meta
          title="Voltaware blog"
          description="Read the latest news on energy use trends and Voltaware insights"
          url="https://www.voltaware.com/blog"
        />

        <section className="section container wrapper">
          <div className="flex-wrap">
            {BlogPosts.map((post, i) => (
              <BlogListItem post={post} key={i} />
            ))}
          </div>
        </section>
      </div>
    );
  }
}

export default Blog;
