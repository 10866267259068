import React from 'react';
import { Link } from 'react-router-dom';

import Meta from '../components/meta.js';

const OurTechnologySmartMeters = () => (
  <section className="__smart-meters-wrap">
    <Meta
      title="Smart Meter Integration"
      description="Voltaware AI platform works with smart meters."
      url="https://www.voltaware.com/our-technology/smart-meters-integration"
    />
    <section className="__smart-meters-header">
      <h1>Smart Meter Integration</h1>
    </section>
    <section className="__smart-meters-section" id="smart-meter-integration">
      <h3 className="dark-grey">Voltaware works with all types of smart meters to provide actionable energy insights.</h3>

      <h2>Smart Meter Disaggregation (15, 30 min)</h2>
      <p>
        Voltaware can already offer weekly and monthly appliance-level insights using only 15 or 30 min meter data. Utilities only need to send meter data once a day, week or month to the Voltaware APIs to retrieve weekly and monthly insights and start engaging customers at scale.
      </p>

      <section className="__smart-meters-wrapper">
        <figure>
          <img
            src="/img/technology/smart-meter-disaggregation-report.png"
            alt="Smart Meter Disaggregation Report"
          />
        </figure>
        <figure>
          <img
            src="/img/technology/smart-meter-disaggregation-report-consumption-breakdown.png"
            alt="Smart Meter Disaggregation Report Consumption Breakdown"
          />
        </figure>
        <figure>
          <img
            src="/img/technology/smart-meter-disaggregation-report-recommendation.png"
            alt="Smart Meter Disaggregation Report Recommendations"
          />
        </figure>
      </section>

      <h2>High Resolution Disaggregation (10 sec)</h2>
      <p>
        In order to provide real-time, higher resolution energy insights to increase customer engagement, Voltaware has a number of meter dongles which can read 10 sec active power data from smart meters, these are very low cost and can be self-installed by the final customer.
      </p>
      <p>
        Voltaware is currently compatible with the following markets:
      </p>

      <section className="__smart-meters-wrapper __smart-meters-wrapper--smaller-images">
        <figure>
          <p className="green">UK (SMET1/2)</p>
          <img
            src="/img/technology/smart-meter-uk-smet12-customer-access-device.png"
            alt="Smart Meter UK (SMET1/2) Customer Access Device"
          />
          <figcaption>Consumer Access Device</figcaption>
        </figure>
        <figure>
          <p className="green">Benelux, Switzerland, Nordics</p>
          <img
            src="/img/technology/smart-meter-benelux-switzerland-nordics-p1-dongle.png"
            alt="Smart Meter Benelux Switzerland Nordics P1 Dongle"
          />
          <figcaption>P1 Dongle</figcaption>
        </figure>
        <figure>
          <p className="green">Germany</p>
          <img
            src="/img/technology/smart-meter-germany-optical-port-reader.png"
            alt="Smart Meter Germany Optical Port Reader"
          />
          <figcaption>Optical port reader</figcaption>
        </figure>
      </section>

      <p>Get in touch with us if you have any questions or specific requirements.</p>

      <div className="buttons-section small-padding-top">
        <Link
          to="/request-a-demo"
          title="Request a demo"
          className="btn-contained call-to-action"
        >
          Request a demo
        </Link>
      </div>
    </section>
  </section>
);

export default OurTechnologySmartMeters;
