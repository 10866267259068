import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Meta from '../components/meta.js';
import PartnersInvestors from '../components/partners-investors.js';

const Utilities = () => (
  <section className="__utilities-wrap">
    <Meta
      title="Flexible Data Analytics For Utilities"
      description="Voltaware helps utilties build a great customer experience through new data-enabled products and generate new revenues."
      url="https://www.voltaware.com/our-offer-to-utilities"
    />
    <section className="__utilities-header">
      <h1>IRIS for utilities</h1>
      <p>IRIS is a customer engagement platform for utilities. It uses AI to transform their customer experience. Unlock new revenues and reduce churn.</p>
      <section>
        <section className="__header-row">
          <figure><img src="/img/utilities/banner-001.png" alt="Customer Retention"/></figure>
          <figure><img src="/img/utilities/banner-002.png" alt="New Revenues"/></figure>
        </section>
        <section className="__header-row">
          <figure><img src="/img/utilities/banner-003.png" alt="Better Business Decisions"/></figure>
          <figure><img src="/img/utilities/banner-004.png" alt="Seamless Experience"/></figure>
        </section>
      </section>
    </section>
    <section className="__utilities-section" id="consumer-goals-evolving">
      <h2>Consumer goals are evolving</h2>
      <p>There is a widening gap between what traditional utilities are currently offering and consumers’ changing needs. Consumers are changing their behaviour to align their energy use with their personal goals, such as reducing bills, improving comfort at home, becoming carbon neutral, and many others…</p>
      <figure className="consumer-goals-desktop"><img src="/img/utilities/consumer-goals-evolving-desktop.png" alt="Consumer Goals Evolving"/></figure>
      <figure className="consumer-goals-mobile"><img src="/img/utilities/consumer-goals-evolving-mobile.png" alt="Consumer Goals Evolving"/></figure>
    </section>
    <section className="__utilities-section" id="reinvent-themselves">
      <h2>Utilities need to reinvent themselves</h2>
      <p className="subtitle">In line with consumers’ changing needs, regulations are opening up the market to new players and rolling out smart meters to transition to green energy. New technologies such as storage, EVs, load shifting, efficient solar panels and many others are supporting this transformation. To be relevant, utilities need to:</p>
      <section className="__utilities-article-wrapper">
        <article>
          <section>
            <h3>Reinvent their value proposition</h3>
            <figure><img src="/img/utilities/reinvent-001.png" alt="Value Proposition"/></figure>
          </section>
          <p>Utilities are shifting from commodity reseller to service provider to align with consumer goals.</p>
        </article>
        <article>
          <section>
            <h3>Make market-driven decisions</h3>
            <figure><img src="/img/utilities/reinvent-002.png" alt="Market-driven Decisions"/></figure>
          </section>
          <p>Utilities need to harness customer insights to identify customer needs and make better decisions.</p>
        </article>
        <article>
          <section>
            <h3>Generate new revenues</h3>
            <figure><img src="/img/utilities/reinvent-003.png" alt="New Revenues"/></figure>
          </section>
          <p>New products and services will help utilities consolidate their market position.</p>
        </article>
      </section>
    </section>
    <section className="__utilities-section" id="iris-by-voltaware">
      <h2>IRIS by Voltaware</h2>
      <p>IRIS is a customer engagement platform for utilities which collects data from smart meters or Voltaware sensors, and uses AI to bring clear benefits to both your business and your customers. IRIS helps you transform your customer experience by building new products and services that address customers’ needs, while unlocking new revenue opportunities and reducing churn.</p>
      <figure className="iris-chart-desktop"><img src="/img/utilities/iris-chart-desktop.png" alt="IRIS by Voltaware"/></figure>
      <figure className="iris-chart-mobile"><img src="/img/utilities/iris-chart-mobile.png" alt="IRIS by Voltaware"/></figure>
      <nav>
        <Link
          to="/our-technology"
          title="Eletricity Data Intelligence"
        >
          How does it work?
        </Link>
      </nav>
    </section>
    <section className="__utilities-section" id="implementing-iris">
      <h2>Implementing IRIS</h2>
      <section>
        <article>
          <section className="label-wrap">
            <figure><img src="/img/utilities/impl-001.png" alt="White-labelled apps"/></figure>
            <h3>White-labelled apps</h3>
          </section>
          <p>Use ready-to-deploy white-labelled Voltaware app or customise it based on your needs.</p>
        </article>
        <article>
          <section className="label-wrap">
            <figure><img src="/img/utilities/impl-002.png" alt="Custom APIs"/></figure>
            <h3>Custom APIs</h3>
          </section>
          <p>Connect to our range of APIs to integrate AI insights into your applications.</p>
        </article>
        <article>
          <section className="label-wrap">
            <figure><img src="/img/utilities/impl-003.png" alt="BI analytics portal"/></figure>
            <h3>BI analytics portal</h3>
          </section>
          <p>Generate custom reports to aggregate consumer insights and make better product decisions.</p>
        </article>
      </section>
    </section>
    <section className="__utilities-section" id="how-it-works">
      <h2>How it works</h2>
      <figure className="how-it-works-desktop"><img src="/img/utilities/how-it-works-desktop.png" alt="How it works"/></figure>
      <figure className="how-it-works-mobile"><img src="/img/utilities/how-it-works-mobile.png" alt="How it works"/></figure>
      <nav>
        <Link
          to="/integrate-with-voltaware"
          title="Integrate with Voltaware"
        >
          Integrate with Voltaware
        </Link>
      </nav>
    </section>
    <section className="__utilities-section" id="innovative-utilities">
      <h2>Our partners</h2>
      <PartnersInvestors showInvestors={false} />
    </section>
    <section className="__utilities-section" id="get-in-touch">
      <h2>Get in touch</h2>
      <p>If you are interested about IRIS, please choose one of the options below.</p>
      <div>
        <Link
          to="/request-a-demo"
          title="Request a demo"
          className="btn-outlined"
        >
          Request a demo
        </Link>
        <a
          href="https://voltaware.as.me/schedule.php"
          alt="Book a demo"
          className="btn-outlined"
        >
          Book a demo
        </a>
      </div>
    </section>
  </section>
);

export default Utilities;
