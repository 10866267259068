import React from 'react';

import {faqs} from '../data/faqs'

// Components
import FaqItem from '../components/faq-item.js';
import Meta from '../components/meta.js';

class Faq extends React.Component {
  render() {
    const {selectedSubcategory, foundFaqs, searchedTerm} = this.props;
    return (
      <section id="faq">
        <Meta
          title="FAQ - Voltaware"
          description="Frequently asked questions about Voltaware."
          url="https://www.voltaware.com/faq"
        />

        {selectedSubcategory && !(foundFaqs && foundFaqs.length) ?
          <div className="section container">
            <div className="faq-items">
              {faqs.filter(faq => faq.subcategory === selectedSubcategory).map((obj, i) => (
                <div className="faq-item-wrapper" key={i}>
                  <FaqItem
                    title={obj.title}
                    observation={obj.observation}
                    items={obj.items}
                    index={i}
                  />
                </div>
              ))}
            </div>
          </div>
          :
          <div className="section container">
            <div className="faq-items">
              <div className="faq-item-wrapper">
                <FaqItem
                  title={foundFaqs && foundFaqs.length === 1 ?
                    `We found 1 result for: ${searchedTerm}` :
                    `We found ${foundFaqs.length} results for: ${searchedTerm}`}
                  observation={null}
                  items={foundFaqs}
                />
              </div>
            </div>
          </div>
        }
      </section>
    );
  }
}

export default Faq;
