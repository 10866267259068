import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BlogListItem extends Component {
  render() {
    let post = this.props.post,
      displayLink = post.link && post.link !== '#';

    return (
      <div className="blog-post">
        {displayLink ? (
          <Link to={post.link} className="blog-post-link">
            <div
              className="blog-post-img"
              style={{
                backgroundImage: `url(${post.cover ? post.cover : post.img})`,
              }}
            ></div>

            <div className="blog-post-info">
              <div className="blog-post-author">
                Posted by {post.author}, {post.date}
              </div>
              <div className="blog-post-title">{post.title}</div>
              <p>{post.preview}</p>

              <button type="button" className="blog-post-btn">
                Read more
              </button>
            </div>
          </Link>
        ) : (
          <div className="blog-post-link">
            <div
              className="blog-post-img"
              style={{
                backgroundImage: `url(${post.cover ? post.cover : post.img})`,
              }}
            ></div>

            <div className="blog-post-info">
              <div className="blog-post-author">
                Posted by {post.author}, {post.date}
              </div>
              <div className="blog-post-title">{post.title}</div>
              <p>{post.preview}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BlogListItem;
