import React from 'react';

// Components
import Meta from '../components/meta.js';

const initialState = {
  loading: false,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  resume: {},
  fieldsWithError: [],
  emailMessage: '',
  emailStatus: ''
};

class JoinUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  change = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      cb(reader.result);
    };

    reader.onerror = error => {
      console.log('Error: ', error);
    };
  }

  uploadResume = e => {
    const file = e.target.files[0];
    this.getBase64(file, result => {
      this.setState({ resume: { content: result, fileName: file.name } });
    });
  };

  submit = e => {
    e.preventDefault();

    this.setState({ loading: true });
    const data = {
      from: `${this.state.firstName} ${this.state.lastName} <${this.state.email}>`,
      subject: "New job apply from Voltaware's website",
      text: `Name: ${this.state.firstName} ${this.state.lastName}
Email: ${this.state.email}
Phone: ${this.state.phone}`,
      attachment: {
        data: this.state.resume.content,
        filename: this.state.resume.fileName
      }
    };

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch('/.netlify/functions/send-email-attachment', config)
      .then(res => {
        if (res.ok) {
          this.reset();
          return this.emailSuccess();
        }

        return this.emailFailure();
      })
      .catch(err => {
        return this.emailFailure();
      });
  };

  errorClass = name => {
    return this.state.fieldsWithError.indexOf(name) >= 0 ? 'error' : '';
  };

  emailSuccess() {
    this.setState({
      loading: false,
      emailMessage: 'Thank you.\nA member of our team will be in touch.',
      emailStatus: 'success'
    });
  }

  emailFailure() {
    this.setState({
      loading: false,
      emailMessage:
        'An error has occurred.\nPlease try again later or contact us directly at jobs@voltaware.com',
      emailStatus: 'error'
    });
  }

  reset = () => {
    this.setState(initialState);
  };

  render() {
    return (
      <section id="join-us">
        <Meta
          title="Join us"
          description="We are always looking for talented professionals to join Voltaware"
          url="https://www.voltaware.com/join-us"
        />

        <section className="section container" id="contact">
          <div className="col about-us-contact">
            <h3 className="title">
              <span>Join Us</span>
            </h3>
          </div>
          {this.state.loading ? (
            <img alt="" src="/img/loading.svg" />
          ) : (
            <form action="#" onSubmit={this.submit}>
              <label>
                First Name*
                <input
                  disabled={this.state.loading}
                  required
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.change}
                />
              </label>
              <label>
                Last Name*
                <input
                  disabled={this.state.loading}
                  required
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.change}
                />
              </label>
              <label>
                Email*
                <input
                  disabled={this.state.loading}
                  required
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.change}
                />
              </label>
              <label>
                Phone*
                <input
                  disabled={this.state.loading}
                  required
                  type="phone"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.change}
                />
              </label>
              <label>
                Resume*
                <input
                  disabled={this.state.loading}
                  accept="application/pdf"
                  required
                  type="file"
                  name="resume"
                  onChange={this.uploadResume}
                />
              </label>

              <p className={this.state.emailStatus}>
                {this.state.emailMessage}
              </p>

              <div className="col">
                <button
                  disabled={this.state.loading}
                  className="btn"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </section>
      </section>
    );
  }
}

export default JoinUs;
